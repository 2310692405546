<template>
  <div id="content">
    <DYLoadingComponent v-if="(asErrorObj instanceof UnauthorizedError)">
    </DYLoadingComponent>
    <div v-else class="errorPage">
      <div class="error-code">
        {{ statusCode }}
      </div>

      <pre v-if="userStore.isCompanyIp">{{ asErrorObj ? asErrorObj.stack : error }}</pre>

      <div class="error-content mt-8">
        <div class="error-message">
          {{ message }}
        </div>
        <div class="error-desc my-8 !text-base">
          {{ forUserMent }} <br> 일시적 장애인 경우 새로고침 하시면 해결됩니다.
        </div>

        <div class>
          <template v-if="'444' == statusCode">
            <v-btn class="px-3 text-white" color="cyan" @click="refresh">페이지 새로고침</v-btn>
          </template>
          <template v-else>
            <!--            v-if="Number(errorCode) !== 404 && Number(errorCode) !== 400"-->
            <div class="mx-auto">
              <template v-if="'502' == statusCode">
                <v-btn class="px-3 text-white" color="cyan" @click="refresh">페이지 새로고침</v-btn>
              </template>
              <v-btn v-if="'401' == statusCode && !userStore.isSignedIn" class="text-white" color="cyan" to="/login"
                append-icon="fal fa-arrow-right">
                로그인
              </v-btn>
              <v-btn v-else class="text-white" color="cyan" @click="errorReportSend" append-icon="fal fa-arrow-right">
                문제 신고하기
              </v-btn>
            </div>
            <div class="flex flex-row flex-wrap justify-center mt-4 gap-4">
              <v-btn v-if="'502' != statusCode" to="/" color="success" class="px-3">
                메인페이지로 이동
              </v-btn>
              <v-btn color="success" class="px-3" @click="historyBack">
                이전 페이지로 이동
              </v-btn>
            </div>
            <div v-if="siteName === '마이퍼스트가이드' && (userStore.isIOSApp || userStore.isAndroidApp)" class="flex flex-row flex-wrap justify-center mt-4 gap-4">
              <v-btn color="blue" class="px-3" @click.prevent="onMyContentsClick">
                다운로드함 바로가기
              </v-btn>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// useMeta({
//   title: "404-Page Not Found : AdminPro NuxtJs Dashboard",
// });

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { convertErrorCode } from '~/modules/nuxt3-module-doyac/composables/common';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { useDYFetchWithToken } from '~/modules/nuxt3-module-doyac/composables/fetch';
import { notify } from '@kyvg/vue3-notification';
import { useDYUserStore } from '~/modules/nuxt3-module-doyac/stores/user';
import { ForbiddenError, UnauthorizedError } from '../composables/auth';

library.add(faArrowRight)

const props = defineProps({
  error: Object
})

const route = useRoute()
const { fullDomain, basePath, siteName } = useDYAppConfig()
const userStore = useDYUserStore()

const referer = ref('알 수 없음')

const statusCode = computed(() => props.error?.statusCode ?? 500)

const asErrorObj = computed(() => props.error instanceof Error ? props.error as Error : undefined)

const message = computed(() => {
  if (props.error?.data?.message) {
    return `${props.error?.data?.message}`
  }

  if (props.error?.message) {
    return `${props.error.message}`
  }

  return convertErrorCode(statusCode.value)
})

const forUserMent = computed(() => {
  if (statusCode.value === 444) { return '일시적 네트워크 에러입니다. 새로고침 후 다시 시도해주세요.' }
  if (statusCode.value !== 404 || statusCode.value !== 400) { return '이 페이지를 보고 계시다면 다른 페이지로 이동하시거나 정상적인 요청을 해 주시기 바랍니다.' }
  if (statusCode.value === 401 && !userStore.isSignedIn) { return '로그인이 만료되었습니다. 다시 로그인해주세요.' }
  return '이 페이지를 보고 계시다면 다른 페이지로 이동하시거나 아래 버튼을 눌러 신고해주세요.'
})

const handleError = () => clearError({ redirect: statusCode.value === 401 ? '/login' : '/' })

const refresh = () => {
  location.reload()
}

const historyBack = () => {
  history.back()
}

const onMyContentsClick = () => {
  if (userStore.isIOS) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.scriptHandler) {
      window.webkit.messageHandlers.scriptHandler?.postMessage({
        script: 'openDownloadManager',
        params: []
      })
    } else {
      window.alert('iOS 앱에서만 제공됩니다.')
    }
  } else {
    location.href = 'myfirstguideapplink://download'
  }
}

const errorReportSend = async (byUser: boolean = true) => {
  try {
    const params = new URLSearchParams()
    if (!byUser) {
      params.append('logOnly', 'true')
    }
    params.append('code', statusCode.value)
    params.append('REQUESTURI', fullDomain + basePath + route.fullPath)
    const { ssrContext } = useNuxtApp()
    params.append('useragent', ssrContext?.event.node.req.headers["user-agent"] ? ssrContext.event.node.req.headers["user-agent"] : 'unknown')
    params.append('referer', ssrContext?.event.node.req.headers.from ? ssrContext.event.node.req.headers.from : referer.value)
    params.append('errorType', ssrContext?.event.node.req.headers.from ? 'spa' : 'ssr')
    params.append('send', 'Y')
    params.append('message', message.value)
    const response = await useDYFetchWithToken('/mer/api/error/report', { method: 'post', body: params })
    if (response) {
      notify({ type: 'success', text: '관리자에게 에러리포트가 전송되었습니다. 감사합니다.' })
    }
    return false
  } catch (e) {
    //
  }
}

onMounted(() => {
  if (props.error) {
    //errorReportSend(false)

    console.error(JSON.stringify(props.error))
  }
})
</script>

<style lang="scss">
.errorPage {
  margin: 0 auto;
  text-align: center;
  min-height: calc(100vh - 255px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.errorPage .error-code,
.errorPage .error-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0)
}

.errorPage .error-code {
  color: #2d353c;
  font-size: 200px;
  line-height: 1;
  text-shadow: 10px 10px 0 rgba(45, 53, 60, .2);
  padding: 1.875rem;
  height: 40vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: flex-end;
  align-items: flex-end
}

@media (max-width:767.98px) {
  .errorPage .error-code {
    font-size: 160px
  }
}

@media (max-width:575.98px) {
  .errorPage .error-code {
    font-size: 120px
  }
}

.errorPage .error-content {
  bottom: 0;
  padding: 1.875rem;
  background: #2d353c;
  background-image: url(https://www.doyac.com/renewal_doyac/img/background/error-page-pattern.png)
}

.errorPage .error-message {
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: .5px;
  margin-bottom: .46875rem
}

.errorPage .error-desc {
  font-size: .75rem;
  color: rgba(255, 255, 255, .5);
  letter-spacing: .5px
}

.btn-primary {
  color: #fff;
  background-color: #00acac;
  border-color: #00acac;
  -webkit-box-shadow: 0;
  box-shadow: 0;
}

.btn-info {
  color: #fff;
  background-color: #49b6d6;
  border-color: #49b6d6;
  -webkit-box-shadow: 0;
  box-shadow: 0;
}

.btn.btn-theme {
  padding: 12px 30px;
  font-weight: 600;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.btn+.btn {
  margin-left: 15px;
}

.v-application .error {
  background-color: inherit !important;
  border-color: inherit !important;
}
</style>
