import validate from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/nuxt/dist/pages/runtime/validate.js";
import loading_45global from "D:/APM_Setup/nuxt/nuxt3-lessonall/middleware/loading.global.ts";
import page_45reset_45global from "D:/APM_Setup/nuxt/nuxt3-lessonall/middleware/page-reset.global.ts";
import re_45render_45when_45error_45global from "D:/APM_Setup/nuxt/nuxt3-lessonall/middleware/re-render-when-error.global.ts";
export const globalMiddleware = [
  validate,
  loading_45global,
  page_45reset_45global,
  re_45render_45when_45error_45global
]
export const namedMiddleware = {
  auth: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/middleware/auth.ts")
}