export function useDYComponent() {
  const $isActivated = ref(false)
  const $isDeactivated = ref(false)

  const _onActivatedCalled = ref(false)
  const _onceDeactivated = ref(false)
  let _onActivated: (() => void) | undefined
  let _onReactivated: (() => void) | undefined
  let _onDeactivated: (() => void) | undefined

  const _activate = () => {
    if (_onActivatedCalled.value) { return }
    if (_onActivated) { _onActivated() }
    _onActivatedCalled.value = true
    
    if (_onceDeactivated.value && _onReactivated) {
      _onReactivated()
    }
  }

  const _deactivate = () => {
    if (_onDeactivated) { _onDeactivated() }
    _onActivatedCalled.value = false
    _onceDeactivated.value = true
  }

  const $onActivated = (callback?: () => void) => {
    _onActivated = callback
  }

  const $onReactivated = (callback?: () => void) => {
    _onReactivated = callback
  }

  const $onDeactivated = (callback?: () => void) => {
    _onDeactivated = callback
  }

  onMounted(() => {
    $isActivated.value = true
    _activate()
  })

  onUnmounted(() => {
    _deactivate()
  })

  onActivated(() => {
    $isActivated.value = true
    $isDeactivated.value = false
    _activate()
  })

  onDeactivated(() => {
    $isActivated.value = false
    $isDeactivated.value = true
    _deactivate()
  })

  return {
    $isActivated,
    $isDeactivated,
    $onActivated,
    $onReactivated,
    $onDeactivated
  }
}