import type { NuxtError } from "nuxt/app";
import type { RouteLocationNormalized, RouteLocationNormalizedLoaded } from "vue-router";
import { useUtils } from "~/modules/nuxt3-module-doyac/composables/utils";
import { useDYMainStore } from "~/modules/nuxt3-module-doyac/stores/main";
import { useDYUserStore } from "~/modules/nuxt3-module-doyac/stores/user";
import { useMainStore } from "~/stores/main";
import { useUserStore } from "~/stores/user";

export function $dy__makePageKey(route: RouteLocationNormalizedLoaded, withSearchParamNames: string[] = []) {
  const { makePageKey } = useUtils()
  return makePageKey(route, withSearchParamNames)
}

export function $dy__showPageError(error: Error | string | Partial<NuxtError>, url: string | RouteLocationNormalized, useThrow = false) {
  const { showPageError } = useUtils()
  showPageError(error, url, useThrow)
}

export function $dy__useDYUserStore() {
  const parent = useDYUserStore()
  return parent
}

export function $dy__useGWUserStore() {
  // 현재 useGWUserStore가 없어서 useDYUserStore를 사용함
  const parent = useDYUserStore()
  return parent
}

export function $dy__useDYMainStore() {
  const parent = useDYMainStore()
  return useMainStore() as typeof parent
}