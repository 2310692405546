<template>
  <div v-if="!mainStore.isLoading" class="doyac-loading" style="display:block;height:400px;position:relative;text-align:center;">
    <span class="left"></span>
    <span class="right" style="position:relative;display:inline-block;left:0px;"></span>
  </div>
</template>

<script setup lang='ts'>
import { useDYMainStore } from "~/modules/nuxt3-module-doyac/stores/main";

const mainStore = useDYMainStore()

</script>
<style lang="scss" scoped>
@import '/modules/nuxt3-module-doyac/assets/scss/animation.scss';

.doyac-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  transition: all 1s
}

.doyac-loading>span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0px 0 0
}

.doyac-loading>span.left {
  background: olive;
  transform: translateX(-30px);
  animation: circleLeft 1s cubic-bezier(.645, .045, .355, 1) infinite
}

.doyac-loading>span.right {
  background: #dc143c;
  transform: translateX(30px);
  animation: circleRight 1s cubic-bezier(.645, .045, .355, 1) infinite
}

.doyac-loading.end {
  opacity: 0;
  z-index: -1
}</style>