import type { RouteLocationNormalized, RouteLocationNormalizedLoaded } from "vue-router";
import { appLink, findIpForSPA, findIpForSSR, formatDate, getFullDomain, join, numberFormat, replaceTags, stripTag, uniqueId, getCFImage } from "../libs/utils";
import { useDYErrorStore } from "../stores/error";
import { useDYUserStore } from "../stores/user";
import type { NuxtError, RouteMiddleware } from "nuxt/app";
import { notify } from "@kyvg/vue3-notification";
import { useDYAuth, type AuthCheckParams } from "./auth";

export interface MakeAuthMiddlewareOptions {
  autoNavigateLoginPage?: boolean
  loginUrl?: string
}

export function useUtils() {
  const makePageKey = (route: RouteLocationNormalizedLoaded, withSearchParamNames: string[] = [], useFullPath: boolean = false) => {
    const params = new URLSearchParams()
    params.set('__e', `${route.query.__e}`)
    withSearchParamNames.forEach(name => params.set(name, `${route.query[name]}`))
    return (useFullPath ? `${route.path}?${route.query}` : route.path).toLowerCase() + '?' + params.toString()
  }

  const showPageError = (error: Error | string | Partial<NuxtError>, url: string | RouteLocationNormalized, useThrow = false) => {
    const userStore = useDYUserStore()
    if (process.server || !userStore.isHuman) {
      if (useThrow) {
        throw createError(error)
      } else {
        showError(error)
      }
    } else {
      const errorStore = useDYErrorStore()
      errorStore.put(typeof error == 'string' ? createError(error) : error, url, useThrow)
    }
  }

  const makeAuthMiddleware = (auth?: string | AuthCheckParams | boolean, opts?: MakeAuthMiddlewareOptions): RouteMiddleware => {
    const _opts = { autoNavigateLoginPage: true, loginUrl: '/login', ...opts }

    return (to, from) => {
      if (process.server) { // 서버에서 동작하는 경우에는 사용자 정보가 없으므로 그냥 넘어감.
        return
      }

      const userStore = useDYUserStore()
      const errorStore = useDYErrorStore()

      // 클라이언트이더라도 최초 페이지 접속 시에는 유저 정보가 없는 상태로 미들웨어가 동작할 수 있음.
      // 유저 정보는 미들웨어 동작 후 페이지 마운트가 일어난 후에 들어오기 때문에 유저 정보가 없다면
      // 그냥 넘겨야 함.
      const nuxtApp = useNuxtApp()
      if (process.client && ((nuxtApp.isHydrating && nuxtApp.payload.serverRendered) || !userStore.isPrepared)) {
        return
      }

      const { hasAuth } = useDYAuth()

      if (userStore.isSignedIn) {
        if (auth && !hasAuth(auth)) {
          errorStore.lastGlobalError = { statusCode: 403 }
          return navigateTo('/error')
          // return abortNavigation({ statusCode: 403 })
        }
      } else {
        if (_opts?.autoNavigateLoginPage) {
          if (!hasAuth(auth)) {
            const cookie = useCookie('auth.redirect')
            cookie.value = to.fullPath
            return navigateTo(_opts.loginUrl)
          }
        } else {
          errorStore.lastGlobalError = { statusCode: 401 }
          return navigateTo('/error')
          // return abortNavigation({ statusCode: 401 })
        }
      }

      // if (!userStore.isSignedIn) {
      //   const cookie = useCookie('auth.redirect')
      //   cookie.value = to.fullPath
      //   if (from.path === '/login') {
      //     mainStore.isLoading = false
      //     return abortNavigation()
      //   } else {
      //     return navigateTo('/login')
      //   }
      // }
    }
  }

  const notifyError = (error: NuxtError | string) => {
    notify({ type: 'error', text: typeof error === 'string' ? error : error.message })
  }

  return {
    makePageKey, showPageError, makeAuthMiddleware, notifyError,
    getCFImage, appLink, findIpForSSR, findIpForSPA, formatDate, getFullDomain, join, replaceTags, stripTag, numberFormat, uniqueId
  }
}
