import type { NuxtError } from "nuxt/app";
import { defineStore } from "pinia";
import { type RouteLocationNormalized } from "vue-router";
import { hasOwnProperty } from "../libs/utils";

export const useDYErrorStore = defineStore('nuxt3-module-doyac-error', () => {
  const lastGlobalError = ref<Error | Partial<NuxtError>>()

  const errors = ref<Record<string, Error | Partial<NuxtError>>>({})

  const put = (error: Error | Partial<NuxtError>, url: string | RouteLocationNormalized, useThrow = true) => {
    errors.value[_makeUrlString(url)] = error
    if (useThrow) {
      throw error
    }
  }

  const get = (url: string | RouteLocationNormalized) => {
    return has(url) ? errors.value[_makeUrlString(url)] : undefined
  }

  const has = (url: string | RouteLocationNormalized) => {
    return hasOwnProperty(errors.value, _makeUrlString(url))
  }

  const remove = (url: string | RouteLocationNormalized) => {
    delete errors.value[_makeUrlString(url)]
  }

  const makeNextUrl = (url: string | RouteLocationNormalized): string => {
    const _url = _makeUrlString(url)
    console.log('_url', _url)
    const tokens = _url?.split('?')
    if (tokens.length === 1) { // 쿼리 스트링이 없던 경우
      return `${_url}?__e=1`
    } else {
      const searchParams = new URLSearchParams(tokens[1])
      if (searchParams.has('__e')) {
        const value = searchParams.get('__e')
        if (value) {
          const intVal = parseInt(value)
          if (!isNaN(intVal)) {
            searchParams.set('__e', `${intVal + 1}`)
            const nextUrl = `${tokens[0]}?${searchParams.toString()}`
            return has(nextUrl) ? makeNextUrl(nextUrl) : nextUrl
          }
        }
      }
      searchParams.set('__e', '1')
      return `${tokens[0]}?${searchParams.toString()}`
    }
  }

  const _makeUrlString = (url: string | RouteLocationNormalized) => {
    return typeof url === 'string' ? url : url.fullPath
  }

  return {
    lastGlobalError,
    errors,
    put,
    get,
    has,
    remove,
    makeNextUrl
  }
})