import { FetchError } from 'ofetch';
import type { NitroFetchRequest, AvailableRouterMethod } from 'nitropack';
import type { AsyncData, FetchResult, UseFetchOptions } from 'nuxt/app';
import type { Ref } from 'vue';
import { useDYUserStore } from '../stores/user';
import { useDYAppConfig } from './config';
import { isJsonString, getFullDomain, hasOwnProperty, uniqueId } from '../libs/utils';
import { convertErrorCode } from './common';
import type { KeysOf, _AsyncData, PickFrom } from 'nuxt/dist/app/composables/asyncData';

type QueryKeys = 'params' | 'query'

function makebaseURLOptions<
  ResT = void,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  opts?: UseFetchOptions<ResT, DataT, PickKeys, DefaultT, ReqT, Method>,
  keyPrefix?: string
) {
  if (opts) {
    const query = (() => {
      if (opts.query) return opts.query.value ?? opts.query
      if (opts.params) return opts.params.value ?? opts.params
    })()

    const key: QueryKeys | undefined = (() => {
      if (opts.query) return 'query'
      if (opts.params) return 'params'
      return undefined
    })()

    if (query) {
      const newQuery: Record<string, any> = {}

      Object.keys(query).forEach((key) => {
        if (typeof query[key] === 'object') {

          const arrKey = key + '[]'

          newQuery[arrKey] = query[key]
        } else {
          newQuery[key] = query[key]
        }
      })

      if (key === 'query' && opts.query) {
        if (opts.query.value) {
          opts.query.value = newQuery
        } else {
          opts.query = newQuery
        }
      } else if (key === 'params' && opts.params) {
        if (opts.params.value) {
          opts.params.value = newQuery
        } else {
          opts.params = newQuery
        }
      }
    }
  }

  if (opts) {
    opts.headers = { ...opts.headers, 'Accept': 'application/json' }
  } else {
    opts = { headers: { 'Accept': 'application/json' } }
  }

  if (!opts.method || `${opts.method}`.toLowerCase() === 'get') {
    const params = opts.params ?? opts.query
    opts.key = (keyPrefix ?? uniqueId) + '-' + JSON.stringify(params)
  }

  // unjs/h3 라이브러리에서 프록시로 DELETE 요청을 바디 없이 보내면 500 에러가 발생함. Node.js 20 버전 이상에서는
  // 괜찮아진다는데 당장은 업그레이드하기 어려우므로 아래처럼 빈 바디를 강제로 붙여서 해결함. 자세한 내용은 링크 참고.
  // https://github.com/unjs/h3/issues/375
  if (`${opts.method}`.toLowerCase() === 'delete' && !opts.body) {
    opts.body = {}
  }

  if (process.client && process.dev) {
    return opts
  }

  const { domain, protocol } = useDYAppConfig()

  if (opts) {
    opts.baseURL = getFullDomain({ domain, protocol })
  } else {
    opts = { baseURL: getFullDomain({ domain, protocol }) }
  }

  return opts
}

function makeAuthOptions<
  ResT = void,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  opts?: UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>,
  keyPrefix?: string
) {
  let _opts = makebaseURLOptions(opts, keyPrefix)

  const userStore = useDYUserStore()
  if (!userStore.accessToken) {
    return _opts
  }

  if (_opts) {
    _opts.headers = { ..._opts.headers, 'Authorization': userStore.accessToken }
  } else {
    _opts = { headers: { 'Authorization': userStore.accessToken } }
  }

  return _opts
}

export function useDYFetch<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  request: string,
  opts?: UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>
): AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | null> {
  return useFetch(request, makebaseURLOptions(opts, request))
}

export async function useDYFetchData<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  request: string,
  opts?: UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>
) {
  const response = await useDYFetch(request, opts)
  try {
    const data = extractDataOrFailure(response)
    return data
  } catch (e: any) {
    const { isHuman } = useDYUserStore()
    console.error(`useDYFetchData ERROR::isHuman`, isHuman)
    console.error(`useDYFetchData ERROR::서버사이드여부`, process.server)
    console.error(`useDYFetchData ERROR::${request}`, JSON.stringify(opts))
    throw e
  }
}

export function useDYLazyFetch<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
  opts?: Omit<UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>, 'lazy'>
): AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | null> {
  return useLazyFetch(request, makebaseURLOptions(opts, `${request}`))
}

export async function useDYLazyFetchData<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
  opts?: Omit<UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>, 'lazy'>
) {
  const response = await useDYLazyFetch(request, opts)
  try {
    const data = extractDataOrFailure(response)
    return data
  } catch (e: any) {
    const { isHuman } = useDYUserStore()
    console.error(`useDYLazyFetchData ERROR::isHuman`, isHuman)
    console.error(`useDYLazyFetchData ERROR::서버사이드여부`, process.server)
    console.error(`useDYLazyFetchData ERROR::${request}`, JSON.stringify(opts))
    throw e
  }
}

/**
 * 토큰이 사용가능한 경우 토큰을 포함하여 `useFetch` 메서드를 실행해주는 메서드.
 * @param request 
 * @param opts 
 * @returns 
 */
export function useDYFetchWithToken<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
  opts?: UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>
): AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | null> {
  return useFetch(request, makeAuthOptions(opts, `${request}`))
}

export async function useDYFetchDataWithToken<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
  opts?: UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>
) {
  const response = await useDYFetchWithToken(request, opts)
  try {
    const data = extractDataOrFailure(response)
    return data
  } catch (e: any) {
    const { isHuman } = useDYUserStore()
    console.error(`useDYFetchDataWithToken ERROR::isHuman`, isHuman)
    console.error(`useDYFetchDataWithToken ERROR::서버사이드여부`, process.server)
    console.error(`useDYFetchDataWithToken ERROR::${request}`, JSON.stringify(opts))
    throw e
  }
}


/**
 * 토큰이 사용가능한 경우 토큰을 포함하여 `useLazyFetch` 메서드를 실행해주는 메서드.
 * @param request 
 * @param opts 
 * @returns 
 */
export function useDYLazyFetchWithToken<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
  opts?: Omit<UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>, 'lazy'>
): AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | null> {
  return useLazyFetch(request, makeAuthOptions(opts, `${request}`))
}

export async function useDYLazyFetchDataWithToken<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
  opts?: Omit<UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>, 'lazy'>
): Promise<AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | null>> {
  const response = await useDYLazyFetchWithToken(request, opts)
  try {
    const data = extractDataOrFailure(response)
    return data
  } catch (e: any) {
    const { isHuman } = useDYUserStore()
    console.error(`useDYLazyFetchDataWithToken ERROR::isHuman`, isHuman)
    console.error(`useDYLazyFetchDataWithToken ERROR::서버사이드여부`, process.server)
    console.error(`useDYLazyFetchDataWithToken ERROR::${request}`, JSON.stringify(opts))
    throw e
  }
}

/**
 * 응답객체(`AsyncData`)에서 데이터만 추출하는 메서드. 만약 데이터가 없다면 에러를 던진다.
 * 
 * @param response 
 * @param directHandleError // 기본값은 넉스트의 에러핸들링. 직접 에러핸들링을 해야하는 경우(백엔드에서 보낸 데이터값에 접근등을 위해)
 *                              true로 인자를 넘기고 catch(e) 에서 e.value로 받아 처리. e.value.statusCode 등으로 접근 (직접 넣은 데이터는 e.value.data)
 * @returns 
 */
export function extractDataOrFailure<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void ? 'get' extends AvailableRouterMethod<ReqT> ? 'get' : AvailableRouterMethod<ReqT> : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(response: _AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | null>, directHandleError = false) {
  if (response.data?.value) {
    return isJsonString(response.data?.value) ? JSON.parse('' + response.data?.value) : response.data.value
  }

  const userStore = useDYUserStore()
  const error = response.error.value

  if (error) {
    if (directHandleError) throw error

    console.error('error', JSON.stringify(error))

    if (hasOwnProperty(error, 'statusCode') && typeof error.statusCode === 'number') {
      const statusCode = error.statusCode
      //@ts-ignore
      // console.log('_error_error', error.message)
      const extractMessage = (_error: Record<string, unknown>) => {
        if (hasOwnProperty(_error, 'data') && typeof _error.data === 'object' && _error.data !== null) {
          if (hasOwnProperty(_error.data, 'message')) {
            return _error.data.message ? `${_error.data.message}` : convertErrorCode(statusCode)
          }
        }
        if (hasOwnProperty(_error, 'message')) {
          console.error('_error.message', _error.message)
        }
        if (hasOwnProperty(_error, 'message') && process.dev) {
          return _error.message as string
        }
        return convertErrorCode(statusCode)
      }

      throw createError({
        statusCode: statusCode,
        message: extractMessage(error)
      })
    }
  }

  console.error('response.status', response.status.value)
  throw createError({ statusCode: 400, message: '요청한 결과를 찾을 수 없습니다.' })
}
