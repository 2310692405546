import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa-svg'
import { VCol } from 'vuetify/components'
// import { ko } from 'vuetify/locale'

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    components: {
      VCol
    },
    display: {
      mobileBreakpoint: 'sm',
      thresholds: {
        xs: 0,
        sm: 577,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
    icons: {
      defaultSet: 'fa',
      aliases,
      sets: {
        fa,
      }
    },
    theme: {
      themes: {
        light: {
          variables: {
            // 'icon-sizes' : 
        //     'border-opacity': 1 // 이게 적용되면 몇몇 컴퍼넌트 CSS가 이상해짐(v-progress-linear가 대표적). 왜 적용했는지 찾아야하나 어디 때문에 했던건지 기억할 수 없다고 하므로 추후 이상한 부분이 있으면 확인해야함.
          }
        }
      }
    }
  })

  nuxtApp.vueApp.use(vuetify)
})
