<template>
  <DYErrorPage :error="error" />
</template>

<script setup lang="ts">
const props = defineProps({
  error: Object
})

const statusCode = computed(() => props.error?.statusCode ?? 404)
const title = computed(() => `에러: ${statusCode}`)

const config = useAppConfig()

useHead({
  meta: [{ content: title }],
  titleTemplate: (titleChunk) => titleChunk ? `${titleChunk} - ${title}` : config.appName
})
</script>

