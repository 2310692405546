export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()

  router.onError((error, to) => {
    if (
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed')
    ) {
      if (!to?.fullPath) {
        window.location.reload();
      } else {
        window.location.href = to.fullPath;
      }
    }
  })
})
