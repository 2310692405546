import { default as _91requestFormNo_93VNQ6BcIoaQMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-gawe/pages/requestForm/teacher/[requestFormNo].vue?macro=true";
import { default as listSNF5f2J49OMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-gawe/pages/requestForm/teacher/list.vue?macro=true";
import { default as _91requestFormNo_93w1DE9cnQg2Meta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-gawe/pages/requestForm/student/[requestFormNo].vue?macro=true";
import { default as list6fdhod9pQjMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-gawe/pages/requestForm/student/list.vue?macro=true";
import { default as manual1vYXKSAPrtMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/payment/manual.vue?macro=true";
import { default as cashyLaV7zJQpqMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/cash.vue?macro=true";
import { default as changepwdhY2mxW5abMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/changepw.vue?macro=true";
import { default as checkpwRZphE3aCmDMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/checkpw.vue?macro=true";
import { default as connectSocialLogind69xjjPt73Meta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/connectSocialLogin.vue?macro=true";
import { default as delmemberu4IBo1UBpzMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/delmember.vue?macro=true";
import { default as pointpffAB85egxMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/point.vue?macro=true";
import { default as recentCheckedvMFWleCjLLMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/recentChecked.vue?macro=true";
import { default as modifyiipcLuzlp4Meta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/join/modify.vue?macro=true";
import { default as step1GMXJ6wo7j0Meta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/join/step1.vue?macro=true";
import { default as app710NWEVGVOMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/introduce/app.vue?macro=true";
import { default as agreeqeGgSICd58Meta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/agree.vue?macro=true";
import { default as downmewYJHhNTVMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/down.vue?macro=true";
import { default as emailCollectRejectOLcRuKpQI7Meta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/emailCollectReject.vue?macro=true";
import { default as indexdeZ4v11oY4Meta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/index.vue?macro=true";
import { default as privacy1PFCeRJShyMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/privacy.vue?macro=true";
import { default as remoteServiceZZQk31pVu5Meta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/remoteService.vue?macro=true";
import { default as _91notice_93u8clymVr59Meta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/notice/[notice].vue?macro=true";
import { default as _91section_93mc7d4fageEMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/findIdPw/[section].vue?macro=true";
import { default as _91list_93zV4G4m97biMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/faq/[list].vue?macro=true";
import { default as _91subMenu_93Mujzm5SA6CMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/[subMenu].vue?macro=true";
import { default as indexnQ81JkBNraMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/index.vue?macro=true";
import { default as landingXPghkLM40wMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/landing.vue?macro=true";
import { default as editz8amHmyE0ZMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/board/[boardName]/[no]/edit.vue?macro=true";
import { default as _91no_93uDO648l21YMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/board/[boardName]/[no].vue?macro=true";
import { default as indexkpAZ4sKBsPMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/board/[boardName]/index.vue?macro=true";
import { default as writebbDDiRlxXpMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/board/[boardName]/write.vue?macro=true";
import { default as error1NIG3wI0wtMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/error.vue?macro=true";
import { default as loginPS3CA6Kx6YMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/login.vue?macro=true";
import { default as login28hzpEq5e4SMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-gawe/pages/login2.vue?macro=true";
import { default as verify_45humanCKv3oc4T0PMeta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/verify-human.vue?macro=true";
import { default as indexFHW4ZrW2O9Meta } from "D:/APM_Setup/nuxt/nuxt3-lessonall/pages/index.vue?macro=true";
export default [
  {
    name: _91requestFormNo_93VNQ6BcIoaQMeta?.name ?? "requestForm-teacher-requestFormNo",
    path: _91requestFormNo_93VNQ6BcIoaQMeta?.path ?? "/requestForm/teacher/:requestFormNo()",
    meta: _91requestFormNo_93VNQ6BcIoaQMeta || {},
    alias: _91requestFormNo_93VNQ6BcIoaQMeta?.alias || [],
    redirect: _91requestFormNo_93VNQ6BcIoaQMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-gawe/pages/requestForm/teacher/[requestFormNo].vue").then(m => m.default || m)
  },
  {
    name: listSNF5f2J49OMeta?.name ?? "requestForm-teacher-list",
    path: listSNF5f2J49OMeta?.path ?? "/requestForm/teacher/list",
    meta: listSNF5f2J49OMeta || {},
    alias: listSNF5f2J49OMeta?.alias || [],
    redirect: listSNF5f2J49OMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-gawe/pages/requestForm/teacher/list.vue").then(m => m.default || m)
  },
  {
    name: _91requestFormNo_93w1DE9cnQg2Meta?.name ?? "requestForm-student-requestFormNo",
    path: _91requestFormNo_93w1DE9cnQg2Meta?.path ?? "/requestForm/student/:requestFormNo()",
    meta: _91requestFormNo_93w1DE9cnQg2Meta || {},
    alias: _91requestFormNo_93w1DE9cnQg2Meta?.alias || [],
    redirect: _91requestFormNo_93w1DE9cnQg2Meta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-gawe/pages/requestForm/student/[requestFormNo].vue").then(m => m.default || m)
  },
  {
    name: list6fdhod9pQjMeta?.name ?? "requestForm-student-list",
    path: list6fdhod9pQjMeta?.path ?? "/requestForm/student/list",
    meta: list6fdhod9pQjMeta || {},
    alias: list6fdhod9pQjMeta?.alias || [],
    redirect: list6fdhod9pQjMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-gawe/pages/requestForm/student/list.vue").then(m => m.default || m)
  },
  {
    name: manual1vYXKSAPrtMeta?.name ?? "payment-manual",
    path: manual1vYXKSAPrtMeta?.path ?? "/payment/manual",
    meta: manual1vYXKSAPrtMeta || {},
    alias: manual1vYXKSAPrtMeta?.alias || [],
    redirect: manual1vYXKSAPrtMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/payment/manual.vue").then(m => m.default || m)
  },
  {
    name: cashyLaV7zJQpqMeta?.name ?? "mypage-cash",
    path: cashyLaV7zJQpqMeta?.path ?? "/mypage/cash",
    meta: cashyLaV7zJQpqMeta || {},
    alias: cashyLaV7zJQpqMeta?.alias || [],
    redirect: cashyLaV7zJQpqMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/cash.vue").then(m => m.default || m)
  },
  {
    name: changepwdhY2mxW5abMeta?.name ?? "mypage-changepw",
    path: changepwdhY2mxW5abMeta?.path ?? "/mypage/changepw",
    meta: changepwdhY2mxW5abMeta || {},
    alias: changepwdhY2mxW5abMeta?.alias || [],
    redirect: changepwdhY2mxW5abMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/changepw.vue").then(m => m.default || m)
  },
  {
    name: checkpwRZphE3aCmDMeta?.name ?? "mypage-checkpw",
    path: checkpwRZphE3aCmDMeta?.path ?? "/mypage/checkpw",
    meta: checkpwRZphE3aCmDMeta || {},
    alias: checkpwRZphE3aCmDMeta?.alias || [],
    redirect: checkpwRZphE3aCmDMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/checkpw.vue").then(m => m.default || m)
  },
  {
    name: connectSocialLogind69xjjPt73Meta?.name ?? "mypage-connectSocialLogin",
    path: connectSocialLogind69xjjPt73Meta?.path ?? "/mypage/connectSocialLogin",
    meta: connectSocialLogind69xjjPt73Meta || {},
    alias: connectSocialLogind69xjjPt73Meta?.alias || [],
    redirect: connectSocialLogind69xjjPt73Meta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/connectSocialLogin.vue").then(m => m.default || m)
  },
  {
    name: delmemberu4IBo1UBpzMeta?.name ?? "mypage-delmember",
    path: delmemberu4IBo1UBpzMeta?.path ?? "/mypage/delmember",
    meta: delmemberu4IBo1UBpzMeta || {},
    alias: delmemberu4IBo1UBpzMeta?.alias || [],
    redirect: delmemberu4IBo1UBpzMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/delmember.vue").then(m => m.default || m)
  },
  {
    name: pointpffAB85egxMeta?.name ?? "mypage-point",
    path: pointpffAB85egxMeta?.path ?? "/mypage/point",
    meta: pointpffAB85egxMeta || {},
    alias: pointpffAB85egxMeta?.alias || [],
    redirect: pointpffAB85egxMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/point.vue").then(m => m.default || m)
  },
  {
    name: recentCheckedvMFWleCjLLMeta?.name ?? "mypage-recentChecked",
    path: recentCheckedvMFWleCjLLMeta?.path ?? "/mypage/recentChecked",
    meta: recentCheckedvMFWleCjLLMeta || {},
    alias: recentCheckedvMFWleCjLLMeta?.alias || [],
    redirect: recentCheckedvMFWleCjLLMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/mypage/recentChecked.vue").then(m => m.default || m)
  },
  {
    name: modifyiipcLuzlp4Meta?.name ?? "join-modify",
    path: modifyiipcLuzlp4Meta?.path ?? "/join/modify",
    meta: modifyiipcLuzlp4Meta || {},
    alias: modifyiipcLuzlp4Meta?.alias || [],
    redirect: modifyiipcLuzlp4Meta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/join/modify.vue").then(m => m.default || m)
  },
  {
    name: step1GMXJ6wo7j0Meta?.name ?? "join-step1",
    path: step1GMXJ6wo7j0Meta?.path ?? "/join/step1",
    meta: step1GMXJ6wo7j0Meta || {},
    alias: step1GMXJ6wo7j0Meta?.alias || [],
    redirect: step1GMXJ6wo7j0Meta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/join/step1.vue").then(m => m.default || m)
  },
  {
    name: app710NWEVGVOMeta?.name ?? "introduce-app",
    path: app710NWEVGVOMeta?.path ?? "/introduce/app",
    meta: app710NWEVGVOMeta || {},
    alias: app710NWEVGVOMeta?.alias || [],
    redirect: app710NWEVGVOMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/introduce/app.vue").then(m => m.default || m)
  },
  {
    name: agreeqeGgSICd58Meta?.name ?? "customerCenter-agree",
    path: agreeqeGgSICd58Meta?.path ?? "/customerCenter/agree",
    meta: agreeqeGgSICd58Meta || {},
    alias: agreeqeGgSICd58Meta?.alias || [],
    redirect: agreeqeGgSICd58Meta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/agree.vue").then(m => m.default || m)
  },
  {
    name: downmewYJHhNTVMeta?.name ?? "customerCenter-down",
    path: downmewYJHhNTVMeta?.path ?? "/customerCenter/down",
    meta: downmewYJHhNTVMeta || {},
    alias: downmewYJHhNTVMeta?.alias || [],
    redirect: downmewYJHhNTVMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/down.vue").then(m => m.default || m)
  },
  {
    name: emailCollectRejectOLcRuKpQI7Meta?.name ?? "customerCenter-emailCollectReject",
    path: emailCollectRejectOLcRuKpQI7Meta?.path ?? "/customerCenter/emailCollectReject",
    meta: emailCollectRejectOLcRuKpQI7Meta || {},
    alias: emailCollectRejectOLcRuKpQI7Meta?.alias || [],
    redirect: emailCollectRejectOLcRuKpQI7Meta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/emailCollectReject.vue").then(m => m.default || m)
  },
  {
    name: indexdeZ4v11oY4Meta?.name ?? "customerCenter",
    path: indexdeZ4v11oY4Meta?.path ?? "/customerCenter",
    meta: indexdeZ4v11oY4Meta || {},
    alias: indexdeZ4v11oY4Meta?.alias || [],
    redirect: indexdeZ4v11oY4Meta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/index.vue").then(m => m.default || m)
  },
  {
    name: privacy1PFCeRJShyMeta?.name ?? "customerCenter-privacy",
    path: privacy1PFCeRJShyMeta?.path ?? "/customerCenter/privacy",
    meta: privacy1PFCeRJShyMeta || {},
    alias: privacy1PFCeRJShyMeta?.alias || [],
    redirect: privacy1PFCeRJShyMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/privacy.vue").then(m => m.default || m)
  },
  {
    name: remoteServiceZZQk31pVu5Meta?.name ?? "customerCenter-remoteService",
    path: remoteServiceZZQk31pVu5Meta?.path ?? "/customerCenter/remoteService",
    meta: remoteServiceZZQk31pVu5Meta || {},
    alias: remoteServiceZZQk31pVu5Meta?.alias || [],
    redirect: remoteServiceZZQk31pVu5Meta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/customerCenter/remoteService.vue").then(m => m.default || m)
  },
  {
    name: _91notice_93u8clymVr59Meta?.name ?? "cs-notice-notice",
    path: _91notice_93u8clymVr59Meta?.path ?? "/cs/notice/:notice()",
    meta: _91notice_93u8clymVr59Meta || {},
    alias: _91notice_93u8clymVr59Meta?.alias || [],
    redirect: _91notice_93u8clymVr59Meta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/notice/[notice].vue").then(m => m.default || m)
  },
  {
    name: _91section_93mc7d4fageEMeta?.name ?? "cs-findIdPw-section",
    path: _91section_93mc7d4fageEMeta?.path ?? "/cs/findIdPw/:section()",
    meta: _91section_93mc7d4fageEMeta || {},
    alias: _91section_93mc7d4fageEMeta?.alias || [],
    redirect: _91section_93mc7d4fageEMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/findIdPw/[section].vue").then(m => m.default || m)
  },
  {
    name: _91list_93zV4G4m97biMeta?.name ?? "cs-faq-list",
    path: _91list_93zV4G4m97biMeta?.path ?? "/cs/faq/:list()",
    meta: _91list_93zV4G4m97biMeta || {},
    alias: _91list_93zV4G4m97biMeta?.alias || [],
    redirect: _91list_93zV4G4m97biMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/faq/[list].vue").then(m => m.default || m)
  },
  {
    name: _91subMenu_93Mujzm5SA6CMeta?.name ?? "cs-subMenu",
    path: _91subMenu_93Mujzm5SA6CMeta?.path ?? "/cs/:subMenu()",
    meta: _91subMenu_93Mujzm5SA6CMeta || {},
    alias: _91subMenu_93Mujzm5SA6CMeta?.alias || [],
    redirect: _91subMenu_93Mujzm5SA6CMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/[subMenu].vue").then(m => m.default || m)
  },
  {
    name: indexnQ81JkBNraMeta?.name ?? "cs",
    path: indexnQ81JkBNraMeta?.path ?? "/cs",
    meta: indexnQ81JkBNraMeta || {},
    alias: indexnQ81JkBNraMeta?.alias || [],
    redirect: indexnQ81JkBNraMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/index.vue").then(m => m.default || m)
  },
  {
    name: landingXPghkLM40wMeta?.name ?? "cs-landing",
    path: landingXPghkLM40wMeta?.path ?? "/cs/landing",
    meta: landingXPghkLM40wMeta || {},
    alias: landingXPghkLM40wMeta?.alias || [],
    redirect: landingXPghkLM40wMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/cs/landing.vue").then(m => m.default || m)
  },
  {
    name: _91no_93uDO648l21YMeta?.name ?? undefined,
    path: _91no_93uDO648l21YMeta?.path ?? "/board/:boardName()/:no()",
    meta: _91no_93uDO648l21YMeta || {},
    alias: _91no_93uDO648l21YMeta?.alias || [],
    redirect: _91no_93uDO648l21YMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/board/[boardName]/[no].vue").then(m => m.default || m),
    children: [
  {
    name: editz8amHmyE0ZMeta?.name ?? "board-boardName-[no]-edit",
    path: editz8amHmyE0ZMeta?.path ?? "edit",
    meta: editz8amHmyE0ZMeta || {},
    alias: editz8amHmyE0ZMeta?.alias || [],
    redirect: editz8amHmyE0ZMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/board/[boardName]/[no]/edit.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexkpAZ4sKBsPMeta?.name ?? "board-boardName",
    path: indexkpAZ4sKBsPMeta?.path ?? "/board/:boardName()",
    meta: indexkpAZ4sKBsPMeta || {},
    alias: indexkpAZ4sKBsPMeta?.alias || [],
    redirect: indexkpAZ4sKBsPMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/board/[boardName]/index.vue").then(m => m.default || m)
  },
  {
    name: writebbDDiRlxXpMeta?.name ?? "board-boardName-write",
    path: writebbDDiRlxXpMeta?.path ?? "/board/:boardName()/write",
    meta: writebbDDiRlxXpMeta || {},
    alias: writebbDDiRlxXpMeta?.alias || [],
    redirect: writebbDDiRlxXpMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/board/[boardName]/write.vue").then(m => m.default || m)
  },
  {
    name: error1NIG3wI0wtMeta?.name ?? "error",
    path: error1NIG3wI0wtMeta?.path ?? "/error",
    meta: error1NIG3wI0wtMeta || {},
    alias: error1NIG3wI0wtMeta?.alias || [],
    redirect: error1NIG3wI0wtMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/error.vue").then(m => m.default || m)
  },
  {
    name: loginPS3CA6Kx6YMeta?.name ?? "login",
    path: loginPS3CA6Kx6YMeta?.path ?? "/login",
    meta: loginPS3CA6Kx6YMeta || {},
    alias: loginPS3CA6Kx6YMeta?.alias || [],
    redirect: loginPS3CA6Kx6YMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/login.vue").then(m => m.default || m)
  },
  {
    name: login28hzpEq5e4SMeta?.name ?? "login2",
    path: login28hzpEq5e4SMeta?.path ?? "/login2",
    meta: login28hzpEq5e4SMeta || {},
    alias: login28hzpEq5e4SMeta?.alias || [],
    redirect: login28hzpEq5e4SMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-gawe/pages/login2.vue").then(m => m.default || m)
  },
  {
    name: verify_45humanCKv3oc4T0PMeta?.name ?? "verify-human",
    path: verify_45humanCKv3oc4T0PMeta?.path ?? "/verify-human",
    meta: verify_45humanCKv3oc4T0PMeta || {},
    alias: verify_45humanCKv3oc4T0PMeta?.alias || [],
    redirect: verify_45humanCKv3oc4T0PMeta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/pages/verify-human.vue").then(m => m.default || m)
  },
  {
    name: indexFHW4ZrW2O9Meta?.name ?? "index",
    path: indexFHW4ZrW2O9Meta?.path ?? "/",
    meta: indexFHW4ZrW2O9Meta || {},
    alias: indexFHW4ZrW2O9Meta?.alias || [],
    redirect: indexFHW4ZrW2O9Meta?.redirect,
    component: () => import("D:/APM_Setup/nuxt/nuxt3-lessonall/pages/index.vue").then(m => m.default || m)
  }
]