import { useDYErrorStore } from "~/modules/nuxt3-module-doyac/stores/error"

/**
 * 에러가 발생했던 페이지에 다시 접근하는 경우 페이지를 다시 렌더링(정확하게는 다른 키로 페이지 새로 생성)하게 해주는
 * 미들웨어.
 */
export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) { // 로딩 UI는 클라이언트 전용임
    return
  }

  const nuxtApp = useNuxtApp()
  // SPA로 이동할 때만 필요함
  if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return

  const errorStore = useDYErrorStore()

  if (errorStore.get(to)) {
    return navigateTo(errorStore.makeNextUrl(to), { replace: true })
  }
})