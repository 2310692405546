import { useDYUserStore } from "../stores/user"
import { useDYMainStore } from "../stores/main"
import type { NuxtError } from "#app"
import type { WatchStopHandle } from 'vue';
import { useDYComponent } from "./dy-component"
import { notify } from "@kyvg/vue3-notification";

type Operator = '>' | '>=' | '<' | '<=' | '=='

interface ScopeStruct {
  group: string
  level?: string | number
}

export interface AuthCheckParams {
  scope: string | ScopeStruct
  operator?: Operator
}

export class UnauthorizedError extends Error {
  readonly loginUrl: string
  readonly autoNavigateLoginPage: boolean

  constructor(message?: string, loginUrl: string = '/login', autoNavigateLoginPage: boolean = true) {
    super(message);
    this.loginUrl = loginUrl
    this.autoNavigateLoginPage = autoNavigateLoginPage
  }
}

export class ForbiddenError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export function useDYAuth() {
  const mainStore = useDYMainStore()
  const route = useRoute()
  const userStore = useDYUserStore()
  const router = useRouter()

  const _saveCurrentUrl = () => {
    const redirect = useCookie('auth.redirect')
    redirect.value = route.fullPath
  }

  async function needLogin() {
    if (!userStore.isSignedIn) {
      _saveCurrentUrl()
      notify({ type: 'error', text: '로그인이 필요한 페이지입니다' })
      await router.push('/login')
      return true
    }
    return false
  }

  function _hasAuth(currentLevel: number | string, requiredLevel: number | string, operator: Operator = '==') {
    switch (operator) {
      case '>': return currentLevel > requiredLevel
      case '>=': return currentLevel >= requiredLevel
      case '<': return currentLevel < requiredLevel
      case '<=': return currentLevel <= requiredLevel
      case '==': return currentLevel == requiredLevel
    }
  }

  function _parseScope(scope: string | ScopeStruct): ScopeStruct {
    if (typeof scope === 'string') {
      if (scope.includes(':')) {
        const tokens = scope?.split(':')
        const level = parseInt(tokens[1])
        return {
          group: tokens[0],
          level: Number.isNaN(level) ? tokens[1] : level
        }
      } else {
        return { group: scope }
      }
    }
    return scope
  }

  function _toAuthCheckParams(value: AuthCheckParams | string): AuthCheckParams {
    if (typeof value === 'string') {
      return { scope: value }
    } else {
      return value
    }
  }

  /**
   * 비교시, 현재 레벨이 좌항, 대상 레벨이 우항으로 볼것. ex) 내 권한 3 >= 기준 권한 5 . 이 경우 권한 없음
   * @param params 
   * @returns 
   */
  const hasAuth = (params: AuthCheckParams | string | boolean | null = null) => {
    if (params === null) {
      return useDYUserStore().isSignedIn
    }

    if (typeof params === 'boolean') {
      return params
    }

    const userStore = useDYUserStore()
    const _params = _toAuthCheckParams(params)

    if (!userStore.scope) {
      return false
    }

    const scope = _parseScope(_params.scope)

    return userStore.scope.some((entry) => {
      const entryScope = _parseScope(entry)

      if (scope.level) {
        return entryScope.level ? scope.group === entryScope.group && _hasAuth(entryScope.level, scope.level, _params.operator) : false
      }
      return scope.group === entryScope.group
    })
  }

  const hasAuthAsync = async (params: AuthCheckParams | string | null | (() => boolean) = null): Promise<boolean> => {
    return typeof params === 'function' ? params() : hasAuth(params)
  }

  const check = (params: AuthCheckParams | string | boolean | null = null, loginUrl = '/login', onFailure: 'throw' | 'show' | ((error: NuxtError) => void) = 'show') => {
    const isAuthorized = typeof params === 'boolean' ? params : hasAuth(params)

    if (!isAuthorized) {
      const { isSignedIn } = useDYUserStore()
      if (isSignedIn) {
        const error = createError({ statusCode: 403 })
        if (typeof onFailure === 'function') {
          onFailure(error)
          return
        } else if (onFailure === 'show') {
          $dy__showPageError(error, useRoute())
          return
        } else {
          throw new ForbiddenError()
        }
        // throw showError({ statusCode: 403 })
      } else {
        const cookie = useCookie('auth.redirect')
        const route = useRoute()
        cookie.value = route.fullPath

        if (onFailure === 'throw') {
          throw new UnauthorizedError(undefined, loginUrl)
        } else {
          return navigateTo(loginUrl, { external: loginUrl.startsWith('http'), replace: true })
        }
      }
    }
  }

  const checkAsync = (params: AuthCheckParams | string | null | (() => boolean) = null, next: () => void) => {
    hasAuthAsync(params).then(hasAuth => {
      if (hasAuth) {
        next()
      } else {
        const { isSignedIn } = useDYUserStore()
        showError({ statusCode: isSignedIn ? 403 : 401 })
      }
    })
  }

  const isAdmin = computed(() => hasAuth('admin'))
  const isTeacher = computed(() => hasAuth('teacher'))
  const isPartner = computed(() => hasAuth('partner'))
  const isEngineer = computed(() => hasAuth({ scope: 'team:tech' }))
  const isCEO = computed(() => hasAuth({ scope: 'admin:1' }))
  const isTeamLeader = computed(() => hasAuth({ scope: 'admin:5' }))

  return {
    /**
     * 권한 판단 메서드. SSR이 지원되지 않는 환경(SPA 전용인 경우) 제대로 판단하지 못하므로 이때는 `hasAuthAsync`
     * 메서드를 사용한다. ** 사용자의 권한 정보(scope)에 접근이 가능한 상황에서는 호출해도 된다.
     */
    hasAuth,
    /**
     * 비동기 권한 판단 메서드. SSR이 지원되지 않을 때 사용한다.
     */
    hasAuthAsync,
    /**
     * 권한 체크 메서드. 권한이 없으면 로그인 페이지로 이동시키거나 에러를 생성한다. SSR이 지원되지 않는 환경(SPA
     * 전용인 경우) 제대로 동작하지 못하므로 이때는 `checkAsync` 메서드를 사용한다. ** 사용자의 권한 정보(scope)에
     * 접근이 가능한 상황에서는 호출해도 된다.
     */
    check,
    /**
     * 비동기 권한 체크 메서드. 권한 판단 후 가능 여부에 따라 콜백을 실행하거나 에러 또는 로그인 페이지로 이동시킨다.
     * SSR이 지원되지 않을 때 사용한다.
     */
    checkAsync,
    /**
     * 로그인 페이지로 보내는 관련 로직을 스크립트화
     */
    needLogin,
    isAdmin,
    isTeacher,
    isPartner,
    isEngineer,
    isCEO,
    isTeamLeader
  }
}

/**
 * 권한 체크용 compasable. 권한 오류 시 로그인 페이지로 이동시키거나 에러 페이지를 렌더링하므로 페이지 또는 페이지 전체를 감싸는 컴퍼넌트에만 사용해야 함.
 * 
 * @param params
 * @param loginUrl 
 * @param onFailure 
 */
export async function useDYCan(params: AuthCheckParams | string | boolean | null = null, loginUrl = '/login', onFailure: 'throw' | 'show' | ((error: NuxtError) => void) = 'throw') {
  const { check } = useDYAuth()
  const userStore = $dy__useDYUserStore()
  const { $onActivated, $isActivated, $onDeactivated } = useDYComponent()

  await check(params, loginUrl, onFailure)

  let unwatch: WatchStopHandle | undefined

  $onActivated(async () => {
    await nextTick()
    await check(params, loginUrl, onFailure)

    // 로그인 상태가 변하면 그에 맞춰 다시 권한 체크를 해야하므로 감시가 필요함
    unwatch = watch(() => userStore.isSignedIn, async (value) => {
      if ($isActivated.value) {
        // 이 시점에서 권한 오류로 예외를 던지게 되면 Nuxt의 onErrorCaptured 훅으로 수신이 불가능함.
        // onErrorCaptured 훅은 created 시점에 던져진 예외만 핸들링하는 것으로 추정됨. 따라서 여기서는
        // 예외를 던지지 않고 스스로 처리하도록 해야하는데 이를 위해서 'show'를 인자로 넘겨줘야 함.
        await check(params, loginUrl, 'show')
      }
    })
  })

  $onDeactivated(() => {
    if (unwatch) {
      unwatch()
      unwatch = undefined
    }
  })
}