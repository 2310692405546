import {useDYUserStore} from "~/modules/nuxt3-module-doyac/stores/user";

export function useAppLinkHandler(androidPackageName: string, appRequired: boolean) {
  const userStore = useDYUserStore()
  if (appRequired === undefined) appRequired = false;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const platform = navigator?.userAgent
  const userAgent = platform?.toLocaleLowerCase();

  function _isChrome() {
    return userAgent.search("chrome") > -1
  }

  function _isAndroid() {
    return userAgent.search("android") > -1
  }

  function _handleAndroid(appLinkUrl:string) {
    const fallbackFunction = function () {
      if (appRequired) window.location.replace('market://details?id=' + androidPackageName);
      else window.location.replace(appLinkUrl);
    };

    const addIFrame = function () {
      const iframe = document.createElement("iframe");
      iframe.style.border = "none";
      iframe.style.width = "1px";
      iframe.style.height = "1px";

      if (_isChrome()) {
        const url = _toIntentScheme(appLinkUrl);
        document.location.replace(url);
      } else {
        iframe.src = appLinkUrl;
      }
      document.body.appendChild(iframe);
    };
    addIFrame();
    setTimeout(fallbackFunction, 250);
  }

  function _toIntentScheme(appLinkUrl:string) {
    if (appLinkUrl.indexOf("://") === -1)
      appLinkUrl = location.origin + appLinkUrl;

    const tokens = appLinkUrl?.split("://")
    const scheme = tokens[0];

    const url = new URL(appLinkUrl.replace(scheme + "://", "http://"));
    const host = url.host;

    if (appLinkUrl.toLowerCase().indexOf("http") === 0) { // HTTP 형태의 웹 링크인 경우
      appLinkUrl = appLinkUrl.replace(location.origin, "");
      return "intent://" + host + "?url=" + encodeURIComponent(appLinkUrl)
        + "#Intent;scheme=" + scheme + ";package=" + androidPackageName + ";end";
    } else { // 앱 전용 링크인 경우
      return "intent://" + host + url.search + "#Intent;scheme=" + scheme + ";package=" + androidPackageName + ";end";
    }
  }

  const open = (appLinkUrl:string) => {
    if (_isAndroid() && !userStore.isAndroidApp) {
      _handleAndroid(appLinkUrl);
    } else {
      location.replace(appLinkUrl);
    }
  }

  return {
    open
  }
}