import { type GWAppConfig } from "./modules/nuxt3-module-gawe/app.config.value"
import { buildVersion } from "./buildVersion"

const basePath = '/v'
// const buildVersion = `1.00-20240405_1129`
// const buildVersion = `1.00-${dayjs().format('YYYYMMDD_HHmmss')}`

//각 프로젝트의 app.config.value.ts 에서 실제 밸류 설정
const appConfig: GWAppConfig = {
  appId: 'nuxt3-lessonall',
  appName: '레슨올',
  /**
   * @deprecated
   * 가능하면 shortHostName 이걸로 쓰자
   */
  beatPlayerId: 'lessonall',
  shortHostName: 'lessonall',
  basePath: basePath,
  buildVersion,
  creatorNameKo: '선생님',
  protocol: 'https',
  dbDomain: 'lessonall',
  domain: 'www.lessonall.com',
  siteName: '레슨올',
  siteNameEN: 'lessonall',
  jusoApiKey: 'U01TX0FVVEgyMDE1MDgwMzEwMTgwNA==',
  fullDomain: 'https://www.lessonall.com',
  /**
   * 풀도메인에 + 베이스패스까지 더함
   */
  fullUrl: 'https://www.lessonall.com/' + basePath,
  GOOGLE_OAUTH_CLIENT_ID: '14995695728-hc42qm516g98rtt5a0hsdm6v5klh5l1p.apps.googleusercontent.com',
  APPLE_SIGNIN_CLIENT_ID: '',
  NAVER_APP_ID: '4Nd2P4jF1iKWmZLWvd3x',
  NAVER_ANALYTICS_ID: 's_2e82a3552c7b',
  ADMIN_PHONE_NUMBER: '1661-3528',
  ANDROID_APP_PACKAGE_NAME: 'com.doyac.android.app.lessonall',
  CUSTOMER_EMAIL_ADDRESS: 'lessonall@lessonall.com',
  CUSTOMER_FAX_NUMBER: '032)714-3538',
  IOS_APP_STORE_ID: '',
  SENTRY_DSN: 'https://9ddb043467c056ec2085779ec045329e@o1097477.ingest.sentry.io/4506534787678208',
  GTAG_SEND_TO: 'AW-1061388127/6V75CP30hZIYEN_-jfoD',
  GOOGLE_RECAPTCHA_CLIENT_KEY: '6Lcdx5wlAAAAADQl6_PVu3dkQxwVm0OeFGjdezkC',
  GOOGLE_ADS_CONVERSION_LABEL: "",
  BRAND_PAY_NAME: '레슨올페이',
  CASH_NAME: "별캐시",
  KAKAO: {
    KAKAO_JAVASCRIPT_KEY: "",
    kakao_plus_friend_account: "",
    kakao_plus_friend_id: ""
  },
  TOSS_PAYMENTS_API_KEY: {
    live: "",
    test: ""
  },
  TOSS_PAYMENTS_API_KVP_KEY: {
    live: "",
    test: ""
  },
  TOSS_PAYMENTS_API_BRAND_KEY: {
    live: "",
    test: ""
  },
  PAYPAL: {
    sandbox: {
      clientId: 'AavkPLX5h-KYU-elVKa9ph0bZc-QLpU7R8HCTjcDBxMvJYz2QUEAQyBUcw-UwUCupiKq9YnYwG9yuQ07'
    },
    live: {
      clientId: 'AYAIoSco8FNqao9jzXWtgBCgG42TaKcNAdWGMJFm2zjkWQSvJNAKGXGtZWxKk__bF6GNLfeV4HrNtvug'
    }
  },
  jsonLd: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: '레슨올',
    url: 'https://www.lessonall.com',
    logo: 'https://www.lessonall.com/m/images/top_logo.svg',
    sameAs: [
      "https://play.google.com/store/apps/details?id=com.doyac.android.app.lessonall&hl=ko"
    ]
  },
  
}

export default appConfig