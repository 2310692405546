import { useDYUserStore } from "~/modules/nuxt3-module-doyac/stores/user"
import { useDYMainStore } from "~~/modules/nuxt3-module-doyac/stores/main"

// 페이지 이동 시 로딩 UI를 노출시키기 위한 전역 미들웨어. 여기서는 로딩 상태로 변경만 하고 해제하지는 못하므로
// 반드시 각 페이지에서 로딩 상태를 해제해야 한다. `DYPage` 컴퍼넌트에서 로딩을 해제하도록 되어 있으므로 모든
// 페이지 컴퍼넌트는 반드시 `DYPage` 컴퍼넌트를 사용해야 한다.
export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) { // 로딩 UI는 클라이언트 전용임
    return
  }

  const nuxtApp = useNuxtApp()
  if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return

  const fromKey = typeof from.meta.key === 'function' ? from.meta.key(from) : from.meta.key
  const toKey = typeof to.meta.key === 'function' ? to.meta.key(to) : to.meta.key

  // 각 라우트의 key가 동일하다면 페이지가 전환된다는 뜻이 아니므로 아무것도 하지 않아야 함. 기존에는 해시 체크까지
  // 했으나 key만 비교해도 충분함.
  if (toKey === fromKey) {
    return
  }

  const mainStore = useDYMainStore()
  mainStore.isLoading = true
  mainStore.isRouteChanging = true
  mainStore.lastScrollY = window.scrollY

  const userStore = useDYUserStore()

  // 유저 로그인 만료 시간 재검사
  if (userStore.isSignedIn) {
    userStore.lastExpirationCheckedTime = new Date()

    // 앱에 의해서 토큰이 갱신되는 경우가 있는 것으로 추정되는데 이 경우 토큰을 일치시켜주지 않으면 오류가 생길 수
    // 있으므로 이하 코드가 필요함.
    // 단, 관리자 강제 접속인 경우에는 동작시키지 않아야 함. 이걸 동작시키면 다시 관리자 토큰을 사용하게 되므로 강제 접속이 해제됨.
    const mockingToken = useCookie('auth._token.laravelPassport_mocking')
    if (!mockingToken.value) {

      const token = useCookie('auth._token.laravelPassport')

      // console.log('userStore.accessToken', userStore.accessToken)
      // console.log('token.value', token.value)

      if (token.value && userStore.accessToken !== token.value) {
        userStore.accessToken = token.value === null ? undefined : token.value
      }
    }
  }
})