<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <NuxtPage :keepalive="{ max: 10 }" />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from './stores/main';
import { useUserStore } from './stores/user';

const { $sentryCaptureException } = useNuxtApp();

const store = useMainStore()
const userStore = useUserStore()

onMounted(async () => {
  // `nuxtTick` 사용 필수!!! 사용하지 않으면 어째서인지 첫번째로 걸린 `await` 네트워크는 실제 요청이 가지 않고 `idle` 상태로 대기되버린다.
  nextTick(async () => {
    await userStore.init()
    store.isLanding = false
  })
})
</script>

<style>
.layout-enter-active,
.layout-leave-active {
  transition: all 0.2s;
}
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>