import revive_payload_client_4sVQNw7RlN from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "D:/APM_Setup/nuxt/nuxt3-lessonall/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_qnOOJwT5Uo from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-user-doyac/plugins/sentry.client.ts";
import router_on_error_client_37Rc4x1IDg from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-doyac/plugins/router-on-error.client.ts";
import notification_fMWCnu41Kr from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-doyac/plugins/notification.ts";
import vuetify_s7JNhFCSfT from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-doyac/plugins/vuetify.ts";
import fontawesome_ckbVx3w98J from "D:/APM_Setup/nuxt/nuxt3-lessonall/modules/nuxt3-module-doyac/plugins/fontawesome.ts";
import plugin_iGLjBbor9q from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_client_LcKgStRyi6 from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "D:/APM_Setup/nuxt/nuxt3-lessonall/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import user_0y9HlFsUwP from "D:/APM_Setup/nuxt/nuxt3-lessonall/plugins/user.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_qnOOJwT5Uo,
  router_on_error_client_37Rc4x1IDg,
  notification_fMWCnu41Kr,
  vuetify_s7JNhFCSfT,
  fontawesome_ckbVx3w98J,
  plugin_iGLjBbor9q,
  plugin_client_LcKgStRyi6,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  user_0y9HlFsUwP
]