import * as Sentry from '@sentry/vue';
import {useDYAppConfig} from '~/modules/nuxt3-module-doyac/composables/config';
import {hasOwnProperty} from '~/modules/nuxt3-module-doyac/libs/utils';
import {ForbiddenError, UnauthorizedError} from "~/modules/nuxt3-module-doyac/composables/auth";

export default defineNuxtPlugin(nuxtApp => {
  // 개발 모드에서는 사용하지 않음
  if (process.dev) {
    return
  }

  const {vueApp} = nuxtApp;
  const {SENTRY_DSN, buildVersion} = useDYAppConfig()

  Sentry.init({
    app: [vueApp],
    dsn: SENTRY_DSN,
    release: buildVersion,
    environment: process.dev ? 'development' : 'production',
    integrations: [
      // 현재 서버 사이드에서 `BrowserTracing`을 사용하면 왜인지 에러가 남. 클라이언트 사이드에서는 문제 없음.
      // 아마도 퍼포먼스 관련 로그를 수집하는 기능으로 보이나 사용하면 금방 무료 사용량을 채우므로 사용하지 않음.
      // new Sentry.BrowserTracing({
      //   routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter())
      // }),
      // 에러가 났을 때 사용자의 동작을 영상으로 기록해주는 기능. 무료 사용량이 너무 적기 때문에 지금은 사용하기 힘듬.
      // new Sentry.Replay()
    ],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    beforeSend(event, hint) {
      const headers = event.request?.headers

      if (headers && headers['User-Agent']) {
        const userAgent = headers['User-Agent']
        // if (userAgent && userAgent.includes('Yeti')) {
        //   return null
        // }
      }

      if (hint.originalException) {
        // 권한 오류는 보고하지 않음
        if ((hint.originalException instanceof UnauthorizedError) || (hint.originalException instanceof ForbiddenError)) {
          return null
        }

        if (hasOwnProperty(hint.originalException, 'cause')) {
          const cause = hint.originalException.cause as Object
          if (hasOwnProperty(cause, 'statusCode')) {
            // 특정 Status Code의 경우 보고하지 않게 함
            if ([401, 403, 404].includes(cause.statusCode as number)) {
              return null
            }
          }
        }

        if (hasOwnProperty(hint.originalException, 'message')) {
          const message = hint.originalException.message as string
          if (message.includes('AbortError')) {
            return null
          }
        }
      }

      // console.log('event.platform', event.request?.headers['User-Agent'])
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, {event, hint});
      }
      // Continue sending to Sentry
      return event
      // return null
    },
    ignoreErrors: [
      "'set' on proxy: trap returned falsish for property '$_sentrySpans'",
      "Large Render Blocking Asset",
      'Request aborted',
      'Network Error',
      /^Request failed with status code ([0-9]+)$/,
      'Cannot set property innerHTML of [object HTMLElement] which has only a getter',
      'ResizeObserver loop limit exceeded',
      'AbortError: The operation was aborted.', // 사파리에서 /moviePlayer 접근 시 주로 발생
      'NavigationDuplicated', // vue-router에서 현재 주소와 같은 주소로 이동하려고 하면 발생,
      'ReportingObserver [intervention]: Ignored attempt to cancel a touchend event with cancelable',
      'ReportingObserver [deprecation]: Deprecation messages are stored in the devtools-frontend repo at front_end/models/issues_manager/DeprecationIssue.ts.',
      /(Event.path)/g, // 현재 deprecated 되어있으며, 2023년 1월에 삭제됨. 이걸 고치려면 해당 코드를 사용하고 있는 라이브러리들이 전부 업데이트 되어야 함.
      'Non-Error promise rejection captured with value: 404'
    ]
  });

  vueApp.mixin(Sentry.createTracingMixins({
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  }));
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  };

})