import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { type CSSProperties } from 'vue'
import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash-es'

interface Button {
  isVisible: boolean
  icon?: IconDefinition
  title?: string
  action?: () => void
  color?: string
  class?: string
}

interface FloatingButtons {
  style: CSSProperties
  isScrollTopButtonVisible: boolean
  button1: Button
  button2: Button
  button3: Button
  button4: Button
  button5: Button
}

export const defaultStyle: CSSProperties = {
  bottom: '20px',
  right: '20px'
}

export const useDYLayoutStore = defineStore('nuxt3-module-doyac-layout', () => {
  const defaultButton: Button = { isVisible: false }
  const defaultFloatingButtons: FloatingButtons = {
    style: defaultStyle,
    isScrollTopButtonVisible: true,
    button1: defaultButton,
    button2: defaultButton,
    button3: defaultButton,
    button4: defaultButton,
    button5: defaultButton
  }

  const isActionBarVisible = ref(true)
  const isActionBarTransparent = ref(false)
  const isTopBannerVisible = ref(true)
  const isTopMenuLineVisible = ref(true)
  const isFooterVisible = ref(true)
  const isRightBannerVisible = ref(true)
  const isRightPannelVisible = ref(false)
  const isChannelTalkButtonVisible = ref(true)
  const isFloatingButtonsVisible = ref(true)
  const isRecentlyViewGoodsVisible = ref(true)
  const floatingButtons = ref(defaultFloatingButtons)
  /**
   * 현재 선택된 액션바 카테고리
   */
  const selectedCategory = ref()

  const reset = () => {
    isActionBarVisible.value = true
    isActionBarTransparent.value = false
    isTopBannerVisible.value = true
    isTopMenuLineVisible.value = true
    isFooterVisible.value = true
    isRightBannerVisible.value = true
    isChannelTalkButtonVisible.value = true
    isFloatingButtonsVisible.value = true
    isRecentlyViewGoodsVisible.value = true
    floatingButtons.value = cloneDeep(defaultFloatingButtons)
    selectedCategory.value = undefined
  }

  return {
    isActionBarVisible,
    isActionBarTransparent,
    isTopBannerVisible,
    isTopMenuLineVisible,
    isRightBannerVisible,
    isChannelTalkButtonVisible,
    isRightPannelVisible,
    isFooterVisible,
    isFloatingButtonsVisible,
    isRecentlyViewGoodsVisible,
    // defaultBannerData: ref()
    mainNuxt: ref(true),
    isIframe: ref(false),
    floatingButtons,
    selectedCategory,
    reset
  }
})
