import { useDYAppConfig } from "~~/modules/nuxt3-module-doyac/composables/config"
import { useDYFetch } from "~~/modules/nuxt3-module-doyac/composables/fetch"
import { useDYUserStore } from "~~/modules/nuxt3-module-doyac/stores/user"

export default defineNuxtPlugin(async ({ $pinia }) => {
  const { fullDomain } = useDYAppConfig()

  const headers = useRequestHeaders()
  const { init, injectBrowserInfo } = useDYUserStore($pinia)

  injectBrowserInfo()

  if (headers['user-agent']) {
    const token = useCookie('auth._token.laravelPassport')
    const expiration = useCookie('auth._token_expiration.laravelPassport')

    // console.log('token', token.value)

    // const response = await useDYFetch<string>(`${fullDomain}/mer/api/ip`)
    // console.log('response', response)

    // const response1 = await useDYFetch<string>(`${fullDomain}/mer/api/ip`)
    // console.log('response1', response1)

    // await init(headers['user-agent'], token.value ?? undefined, expiration.value ? parseInt(expiration.value) : undefined)
  }
})