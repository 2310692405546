// 페이지 이동 시 적용되는 글로벌 미들웨어
// 기존에는 dypage내에서 했으나 이경우 spa이동시 순서보장이 되지 않는 문제 때문에 라우트 미들웨어 사용

import { useDYLayoutStore } from "~/modules/nuxt3-module-doyac/stores/layout"
import { useDYMainStore } from "~/modules/nuxt3-module-doyac/stores/main"

// 페이지 컴퍼넌트는 반드시 `DYPage` 컴퍼넌트를 사용해야 한다.
export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) { // 로딩 UI는 클라이언트 전용임
    return
  }

  // 페이지는 그대로인데 모달등 클릭했을떄는 라우트 변경의 처리시 일어나는 프로세스를 타면 안됨
  if(to.path === from.path && to.hash !== from.hash) return

  const layout = useDYLayoutStore()
  layout.reset()

  const mainStore = useDYMainStore()
  mainStore.actionbarTitle = ''
})