import { defineStore, storeToRefs } from 'pinia'
import appConfig from '~~/app.config.value'
import { useGWMainStore } from '~~/modules/nuxt3-module-gawe/stores/main'

const { appId } = appConfig

export const useMainStore = defineStore(`${appId}-main`, () => {
  const parent = useGWMainStore()
  const refs = storeToRefs(parent)
  return {
    ...refs
  }
})