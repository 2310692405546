/**
 * API list 요청 시 공통적으로 반환되는 형태를 정의한 인터페이스.
 */
export interface ApiListStructure<T> {
  items: T[]
  totalCount: number
  /**
   * 없을 수도 있는 값
   */
  message?: string
  hasNext?: boolean
}

/**
 * 이전의 ApiCUDStructure 를 대체
 */
export interface ApiSimpleResponseStructure {
  /**
   * allow로 보냈다면 true, deny로 보냈다면 false
   */
  allowed: boolean
  code?: number
  /**
   * 설정한 메시지를 받으며, 아무것도 설정하지 않았다면 null로 오게 된다
   */
  message?: string
}

/**
 * 백엔드에서 만들어지는 공통의 이미지 객체
 */
export interface ImageSet {
  dataSet: string
  original: string
  thumbnail: string
  thumbnail_l: string
  thumbnail_m: string
  thumbnail_s: string
}
export type ImageSetFields = keyof ImageSet | '$all'

export interface DYBankAccount {
  bank: string
  number: string
  owner: string
}

export function convertErrorCode(code: number) {
  switch (code) {
    case 100: return 'Continue';
    case 101: return 'Switching Protocols';
    case 200: return 'OK';
    case 201: return 'Created';
    case 202: return 'Accepted';
    case 203: return 'Non-Authoritative Information';
    case 204: return 'No Content';
    case 205: return 'Reset Content';
    case 206: return 'Partial Content';
    case 300: return 'Multiple Choices';
    case 301: return 'Moved Permanently';
    case 302: return 'Moved Temporarily';
    case 303: return 'See Other';
    case 304: return 'Not Modified';
    case 305: return 'Use Proxy';
    case 400: return 'Bad Request';
    case 401: return '해당 페이지에 대한 권한이 없습니다.(Unauthorized)';
    case 402: return 'Payment Required';
    case 403: return '해당 페이지에 대한 권한이 없습니다.(Forbidden)';
    case 404: return '해당 페이지를 찾을 수 없습니다.';
    case 405: return 'Method Not Allowed';
    case 406: return 'Not Acceptable';
    case 407: return 'Proxy Authentication Required';
    case 408: return 'Request Time-out';
    case 409: return 'Conflict';
    case 410: return 'Gone';
    case 411: return 'Length Required';
    case 412: return 'Precondition Failed';
    case 413: return 'Request Entity Too Large';
    case 414: return 'Request-URI Too Large';
    case 415: return 'Unsupported Media Type';
    case 500: return '해당 페이지에서 알 수 없는 오류가 발생하여 정상적으로 열 수 없습니다.';
    case 501: return 'Not Implemented';
    case 502: return 'Bad Gateway';
    case 503: return 'Service Unavailable';
    case 504: return 'Gateway Time-out';
    case 505: return 'HTTP Version not supported';
    default: return '에러'
  }
}

export function isCompanyIp(ip: string) {
  return [
    '121.66.53.58',
    '121.66.53.59',
    '121.66.53.61',
    '121.66.53.62',
    '114.207.244.47',
    '1.234.21.191',
    '112.220.197.86',
    '192.168.0.56', // 명진PD 폰
    '192.168.0.70',
    '192.168.0.75',
    '192.168.0.76'
  ].includes(ip)
}
