// import type { ApiSimpleResponseStructure } from "./common"
// import { DYLoginResponse } from "./dy-common-login"
// import { type DYFileFormData } from "./dy-file-uploader"
import type { ApiListStructure, ApiSimpleResponseStructure } from "./common"
import type { DYLoginResponse } from "./dy-common-login"
import type { DYFileFormData } from "./dy-file-uploader"
import { extractDataOrFailure, useDYFetch, useDYFetchWithToken } from "./fetch"

export interface DYMember {
  email: string
  email_state: string
  google_id: null | string
  id: string
  isAppleJoin: boolean
  isFacebookOath: boolean
  isGoogleJoin: boolean
  isGoogleOath: boolean
  isNaverJoin: boolean
  isNaverOath: boolean
  isOAuthPasswordAssigned: boolean
  isThirdPartyJoin: boolean
  isoCode2: string
  join_root: string
  mailing: boolean
  name: string
  naver_id: null | string
  needAppleLogin: boolean
  nickname: null | string
  no: number
  notify_mailing: number
  oAuthPasswordAssigned: number
  p_id: null | string
  pcs_certify: string
  privacyExpiry: number
  profileImage: string
  profileImageThumbNail: {
    l: string
    m: string
    s: string
    t: string
  }
  protectedName: string
  register_day: number
  sex: string
  upfile1: string
  wrong_number: boolean
  hp?: string
  tel?: string
  birthday?: string
  last_login?: number
  address1?: string
  address2?: string
  zip?: string
  vipGrade?: number
  vipGradeEn?: string
  vipGradeImage?: string
  vipGradeKo?: string
  isBadCustomer?: boolean
  accessRoute?: string
  isNaverAssociated?: boolean
  isGoogleAssociated?: boolean
  isAppleAssociated?: boolean
  countryInfo?: {
    isoCode3: string
    isoCode2: string
    countryName: string
  }
  confirmCaptcha?: string
  isPossiblePasswordLogin?: boolean
  availablePoint: number
  availableCash: number
  /**
   * 결제한 총액. 관리자 전용.
   */
  totalPaidPrice?: number
}

export interface DYMemberInsertParams {
  id: string
  name: string
  pwd: string
  pwdConfirm: string
  hp: string
  join_root: string
  isoCode2?: string
  thirdPartyId?: string
  nickname?: string
  upfile1?: string
  mailing?: boolean
  accessRoute?: string
  profileImageData?: DYFileFormData
}

export interface MemberUpdateParams {
  email: string,
  hp: string,
  isoCode2?: string,
  nickname?: string,
  upfile1?: string
  mailing?: boolean,
  pcs_certify?: string,
  /**
   * 개인정보 유효기간
   */
  privacyExpiry?: number
  profileImageData?: DYFileFormData
}

export interface FindIdPwParams {
  section: string
  with: string
  id?: string,
  name: string,
  tel?: string,
  email?: string
}

export interface MemberDelParams {
  pwd?: string,
  'pwd_ok'?: string,
}

export interface ListResult<T> {
  items: T[]
  totalCount: number
}

type DYMemberApiFields
  = keyof DYMember
  | '$all'

export interface DYMemberGetListParams {
  $fields?: DYMemberApiFields[]
  page?: string | number
  perPage?: string | number
  keyword?: string
  vipGrade?: string
  mailing?: 'y'|'n'
  email?: string
  email_state?: 'ok'
  news_letter?: string
  id?: string
}

export function useDYMember() {

  /**
   * 본인이 본인 정보 요청할 때 좀 더 가져와야할 데이터가 있어서 토큰 필요
   * @param userNo 
   * @returns 
   */
  const get = async (userNo: number, $fields?: DYMemberApiFields[]) => {
    const response = await useDYFetchWithToken<DYMember>(
      `/mer/api/members/${userNo}`,
      { method: 'get', query: { $fields: JSON.stringify($fields) } }
    )
    return extractDataOrFailure(response)
  }

  const insert = async (params?: DYMemberInsertParams) => {
    const response = await useDYFetch<DYLoginResponse>('/mer/api/members', { method: 'post', body: { ...params } })
    return extractDataOrFailure(response)
  }

  const update = async (userNo: number, params?: MemberUpdateParams) => {
    const response = await useDYFetchWithToken<DYMember>('/mer/api/members/' + userNo, { method: 'patch', body: { ...params } })
    return extractDataOrFailure(response)
  }

  const destroy = async (userNo: number, params?: MemberDelParams) => {
    const response = await useDYFetchWithToken<{ allowed: boolean, message: string, code: number | null }>(`/mer/api/members/${userNo}`, { method: 'delete', body: { ...params } })
    return extractDataOrFailure(response)
  }

  const changePw = async (userNo: number, params: { pwd: string, pwd_ok: string }) => {
    const response = await useDYFetchWithToken<ApiSimpleResponseStructure>(`/mer/api/members/change-password/${userNo}`, { method: 'post', body: params })
    return extractDataOrFailure(response)
  }

  /**
   * 비번 검사만
   */
  const checkPw = async (userNo: number, pwd: string) => {
    const response = await useDYFetchWithToken<{ allowed: boolean, message: string, code: number | null }>(`/mer/api/members/check-password/${userNo}`, { method: 'post', body: { pwd } })
    return extractDataOrFailure(response)
  }

  const findId = async (params?: FindIdPwParams) => {
    const response = await useDYFetchWithToken<{ allowed: boolean, message: string, code: number | null }>('/mer/api/members/findId', { method: 'post', body: params })
    return extractDataOrFailure(response)
  }

  const findPw = async (params?: FindIdPwParams) => {
    const response = await useDYFetchWithToken<{ allowed: boolean, message: string, code: number | null }>('/mer/api/members/findPw', { method: 'post', body: params })
    return extractDataOrFailure(response)
  }

  /**
   * @param param 휴대폰 인증넘버를 보낸다
   * 휴대폰 인증시킨다. 유저 정보 필수로 짜여져 있음!
   */
  const certifyPcs = async (param: { code: number, pcs: string }) => {
    const response = await useDYFetchWithToken<{ success: boolean, message: string }>('/mer/api/members/certify-pcs', { method: 'post', body: param })
    return extractDataOrFailure(response)
  }

  /**
   * @param param 휴대폰 넘버를 보낸다
   * 휴대폰 인증을 위한 인증번호를 보낸다. 유저 정보 필수로 짜여져 있음!
   */
  const sendCertifyingCode = async (param: { number: string, sendType: 'message' | 'kakao' }) => {
    const response = await useDYFetchWithToken<{ success: boolean, message: string }>('/mer/api/members/send-certifying-code', { method: 'post', body: param })
    return extractDataOrFailure(response)
  }

  /**
   * 회원 목록 조회 API. 관리자 권한 필요
   * @param params 
   * @returns 
   */
  const getList = async (params?: DYMemberGetListParams): Promise<ApiListStructure<DYMember>> => {
    const response = await useDYFetchWithToken<ApiListStructure<DYMember>>(
      '/mer/api/members',
      { query: { ...params, $fields: JSON.stringify(params?.$fields) } }
    )
    return extractDataOrFailure(response)
  }

  /**
   * 블랙 커스토머 등록/해제 API. 관리자 권한 필요
   * @param userNo 
   * @returns 
   */
  const toggleBadCustomer = async (userNo: number) => {
    const response = await useDYFetchWithToken<DYMember>(
      `/mer/api/members/${userNo}/bad-customer`,
      { method: 'post', body: {} }
    )
    return extractDataOrFailure(response)
  }

  /**
   * 의심 아이디(캡차 인증) 등록/해제 API. 관리자 권한 필요
   * @param userNo 
   * @returns 
   */
  const toggleConfirmCaptcha = async (userNo: number) => {
    const response = await useDYFetchWithToken<DYMember>(
      `/mer/api/members/${userNo}/confirm-captcha`,
      { method: 'post', body: {} }
    )
    return extractDataOrFailure(response)
  }

  /**
   * 불량번호 등록/해제 API. 관리자 권한 필요
   * @param userNo
   * @returns 
   */
  const toggleWrongNumber = async (userNo: number) => {
    const response = await useDYFetchWithToken<DYMember>(
      `/mer/api/members/${userNo}/wrong-number`,
      { method: 'post', body: {} }
    )
    return extractDataOrFailure(response)
  }

  /**
   * 임시 비밀번호 발행 API. 관리자 권한 필요
   * @param userNo 
   */
  const sendTemporalPassword = async (userNo: number) => {
    const response = await useDYFetchWithToken<DYMember>(
      `/mer/api/members/${userNo}/send-temporal-password`,
      { method: 'post', body: {} }
    )
    return extractDataOrFailure(response)
  }

  /**
   * CS용 아이디 전송 API. 관리자 권한 필요.
   * @param userNo
   * @returns 
   */
  const sendId = async (userNo: number) => {
    const response = await useDYFetchWithToken<DYMember>(
      `/mer/api/members/${userNo}/send-id`,
      { method: 'post', body: {} }
    )
    return extractDataOrFailure(response)
  }

  return {
    get, insert, update, destroy, checkPw, findId, findPw, certifyPcs,
    sendCertifyingCode, changePw,
    // 관리자 전용 메서드들
    getList, toggleBadCustomer, toggleConfirmCaptcha, toggleWrongNumber, sendTemporalPassword, sendId
  }
}