import { type LocationQueryValue } from 'vue-router';
import { notify } from "@kyvg/vue3-notification";
import dayjs from "dayjs";
import 'dayjs/locale/ko';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import DOMPurify from 'dompurify';
import { isArray, isString } from 'lodash-es';
import { type DisplayBreakpoint } from "vuetify";
import type { DYAppConfig } from "../app.config.value";
import type { ApiSimpleResponseStructure } from "../composables/common";
import { useDYAppConfig } from "../composables/config";
import type CloudFlareFlexibleVariants from "./CloudFlareFlexibleVariants";
import { useAppLinkHandler } from '~/modules/nuxt3-module-doyac/composables/appLinkHandler';


export const animateCSS = (element: Element, animation: string, prefix = '') =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`

    element.classList.add(`${prefix}animated`, animationName)

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event: Event) {
      event.stopPropagation()
      element.classList.remove(`${prefix}animated`, animationName)
      resolve('Animation ended')
    }

    element.addEventListener('animationend', handleAnimationEnd, { once: true })
  })

export const apiListStructureCudNotify = (response: ApiSimpleResponseStructure) => { // CUD에 해당하는 노티파이 처리
  if (response.message) { notify({ type: response.allowed ? 'success' : 'error', text: response.message }) }
}

export const appLink = (url: string) => {
  const { ANDROID_APP_PACKAGE_NAME } = useDYAppConfig()
  const useAppLink = useAppLinkHandler(ANDROID_APP_PACKAGE_NAME, true)

  useAppLink.open(url)
}

/**
 * 클립보드 복사 기능. IOS도 지원될것임 - 개발버전에선 확인 불가하고 배포해야만 가능
 * @param copiedText 
 * @param copiedMent 
 */
export const clipboard = (copiedText: string, copiedMent?: string) => {
  if (!copiedMent) copiedMent = '클립보드 복사되었습니다'

  if (process.client) { //writeText는 클립보드에 해당 문구를 쓴다는 개념.
    if (process.dev) {
      notify({ type: 'warn', text: '개발버전은 안됩니다' })
    } else {
      navigator?.clipboard?.writeText(copiedText).then(
        () => {
          // notify({ type: 'success', text: copiedText + '\n\n' + copiedMent }) //원랜 이거였는데 대표님이 일단 필요없을것 같다해서 주석
          notify({ type: 'success', text: copiedMent })
        }
      ).catch(err => {
        console.log('Something went wrong', err);
      })
    }
  }
}

/**
 * SSR 공격 등을 막기 위한 공격성 태그 제거 코드. 이걸 하고 v-html에서 사용하면 됨
 * @param text 
 * @returns 
 */
export const domPurify = (text: string) => {
  const purify = DOMPurify() //SSR공격 등 방지 위한 코드
  if (!purify) return text
  return purify.sanitize(text)
}

export const isJsonString = (str?: any) => {
  if (!str || !isString(str)) return false

  try {
    var json = JSON.parse(str);
    return (typeof json === 'object');
  } catch (e) {
    return false;
  }
}

/**
 * 뷰3 라우트쿼리 타입이 LocationQueryValue 이기 때문에 발생하는 타입스크립트 문제 해결을 위한 유틸함수. string|string[] 으로 리턴함
 * @param query 
 * @returns string|string[]
 */
export const parserRouteQuery = (query: LocationQueryValue | LocationQueryValue[]) => {
  if (!query) return undefined

  if (isArray(query)) {
    return query.map((item) => {
      return String(item)
    })
  } else {
    return String(query)
  }
}

export const getQueryObject = (path: string | URL) => {
  const { fullDomain } = useDYAppConfig()

  if (typeof path === 'string') {
    if (!path.startsWith('/')) { path = '/' + path }

    return Object.fromEntries(new URL(fullDomain + path).searchParams)
  } else {
    return Object.fromEntries(path.searchParams)
  }
}

/**
 * 에러 메시지 기본 핸들링
 * @param error // 에러 객체
 */
export const errorMessageHandler = (error: any) => {
  return error.statusCode === 403 && error.message === '해당 페이지에 대한 권한이 없습니다.(Forbidden)' ? '작성한 내용 중 허용되지 않는 문자열 조합이 있거나, 권한이 없습니다.' : error.message
}

/**
 * SSR 모드일 때 IP를 알아낼 수 있는 메서드. SSR 모드는 node.js 서버가 동작하는 환경이므로 직접 `Request` 객체를 통해 IP를 알아낼 수 있다.
 * @param request 
 * @returns 
 */
export function findIpForSSR(request: any): string | undefined {
  return request.headers['x-forwarded-for']?.split(',')?.pop()?.split(':')?.pop() || // From proxy headers, can be spoofed if you don't have a proxy in front of your app, so drop it if your app is naked.
    request.connection?.remoteAddress ||
    request.socket?.remoteAddress || // socket is an alias to connection, just delete this line
    request.connection?.socket?.remoteAddress
}

/**
 * SPA 모드일 때 IP를 알아낼 수 있는 메서드. SPA 모드에서는 서버가 없으므로 실제 IP를 알아낼 수 없기 때문에 별도의 서버에 새로운 요청을 보내서 알아내야 한다.
 */
export async function findIpForSPA(): Promise<string> {
  const response = await fetch(`/mer/api/ip`)
  return response.text()
}

export function formatDate(date: Date | number | string, format?: string) {
  const time = isNaN(Number(date)) || date instanceof Date ? dayjs(date) : dayjs.unix(Number(date)) //좌항이면 string이라 date형식, 우항이면 타임스탬프
  if (!format) format = 'YYYY-MM-DD'
  return time.format(format)
}

/**
 * 프로토콜을 포함한 도메인 주소를 반환하는 메서드. `https://www.doyac.com` 형태로 반환된다.
 * @param config 
 * @returns 
 */
export function getFullDomain(config: Pick<DYAppConfig, 'protocol' | 'domain'>): string {
  return `${config.protocol}://${config.domain}`
}

export function numberFormat(value: number) {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return 0
}

export function join(value: string, glue?: string) {
  if (!Array.isArray(value)) { return }
  if (glue === undefined) { glue = ', ' }
  return value.reduce((prev, current) => `${prev}, ${current}`)
}

/**
 * typedPk를 type, pk로 파싱해주는 함수.
 * @param typedPk
 */
export function parseTypedPk(typedPk: string) {
  if (!typedPk) { return { type: undefined, pk: undefined } }
  let pos = 0
  while (pos < typedPk.length) {
    if (!isNaN(Number(typedPk[pos]))) {
      break
    }
    pos++
  }

  return {
    type: typedPk.substring(0, pos),
    pk: Number(typedPk.substring(pos))
  }
}

/**
 * 보여주고 싶지 않은 부분들 제외하고 실제 컨텐츠만 프린트시키기 위함. hiddenPrintArea 클래스로 보여주지 않을 부분들 설정 필요
 */
export function print() {
  window.onbeforeprint = () => {
    document.querySelectorAll('.hiddenPrintArea').forEach((item) => {
      item.classList.add('!hidden')
    })
  }

  window.onafterprint = () => {
    document.querySelectorAll('.hiddenPrintArea').forEach((item) => {
      item.classList.remove('!hidden')
    })
  }
  try {   ////safari용
    document.execCommand('print', false);
  }
  catch (e) {
    window.print();
  }
}

export function protectedText(originalString: string) {
  const length = originalString.length
  let resultText

  if (length > 4) {
    resultText = originalString.substring(0, 2)
    for (let i = 2; i < length - 2; i++) {
      resultText += '*'
    }

    resultText += originalString.slice(-2)
    if (length > 10) {
      const halfLength = Math.round(length) - 2
      const hiddenArea = originalString.substring(halfLength)
      resultText = resultText.substring(0, halfLength) + hiddenArea + resultText.substring(halfLength + 3, length)
    }
  } else if (length > 3) {
    resultText = originalString.substring(0, 1) + '**' + originalString.substring(length - 1, length)
  } else if (length > 2) {
    resultText = originalString.substring(0, 1) + '*' + originalString.substring(length - 1, length)
  }

  return resultText
}

export function replaceTags(originalString: string) {
  if (!originalString) { return originalString }
  if (originalString) { return originalString.replace(/(&lt;)/gi, '<').replace(/&gt;/gi, '>') }
}

/**
 * getQuerySelector로 가져온 엘리먼트를 넣어주면 됨
 * @param qeurySelector 
 */
export function scrollToElement(qeurySelector: string) {
  nextTick(async () => {
    const elm = document.querySelector(qeurySelector)

    if (elm instanceof HTMLElement) {
      await window.scrollTo({ top: elm?.offsetTop + 100 })
    }
  })
}


/**
 * ref로 지정한 뷰 컴포넌트로 이동
 * @param html // ref로 지정한 뷰 컴포넌트 자체를 넘길것. ex) scrollToRef(AAcomponent.value)
 */
export function scrollToRef(html?: HTMLElement, area?: HTMLElement, topModifier?: number) {
  if (process.client) {
    if (!html) {
      throw new Error(`scrollToRef 에러!! ref 찾을 수 없음 ==>> ${html}`)
    }

    if (!topModifier) topModifier = 0

    if (html instanceof HTMLElement) {
      if (window.IntersectionObserver) {
        if (area) {
          area.scrollTo({ top: window.scrollY + html.getBoundingClientRect().top - 5 + topModifier })
        } else {
          window.scrollTo({ top: window.scrollY + html.getBoundingClientRect().top - 5 + topModifier })
        }

      } else {
        if (area) {
          area.scrollTo(0, window.scrollY + html.getBoundingClientRect().top + 5 + topModifier)
        } else {
          window.scrollTo(0, window.scrollY + html.getBoundingClientRect().top + 5 + topModifier)
        }
      }
    }
  }
}

export function stripTag(originalString: string | undefined) {
  if (originalString === undefined) return ''
  if (!originalString) { return originalString }
  return originalString.replace(/<br\s*\/?>/gi, ' ').replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, ' ')
}

export function stripSpecialCharacters(originalString: string) {
  if (!originalString) { return originalString }
  return originalString.replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi, '')
}

/**
 * 유니크 아이디 생성 함수.
 */
export function uniqueId() {
  function s4() {
    return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1)
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

/**
 * 주어진 객체에 특정 프로퍼티가 존재하는지 체크하는 함수. 타입스크립트에서 유용하게 쓰임
 * if문에서 쓸 것, bool을 리턴하며, true인 경우에는 해당 프로퍼티로 바로 접근할 수 있게 된다
 * 타입스크립트에서만 알고 있는 인터페이스로 정확한 타입을 체크할때도 유용함
 * @param obj
 * @param prop
 */
export function hasOwnProperty<X extends object, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop)
}

export function getBrowserInfo(userAgent: string) {
  let reg = null
  let result
  const browser = { name: '', version: '' }
  userAgent = userAgent.toLowerCase()
  if (userAgent.includes('opr')) {
    reg = /opr\/(\S+)/g
    browser.name = 'Opera'
    result = reg.exec(userAgent)
    if (result) { browser.version = result[1] }
  } else if (userAgent.includes('edge')) {
    reg = /edge\/(\S+)/g
    browser.name = 'Edge'
    result = reg.exec(userAgent)
    if (result) { browser.version = result[1] }
  } else if (userAgent.includes('chrome')) {
    reg = /chrome\/(\S+)/g
    browser.name = 'Chrome'
    result = reg.exec(userAgent)
    if (result) { browser.version = result[1] }
  } else if (userAgent.includes('safari')) {
    reg = /safari\/(\S+)/g
    browser.name = 'Safari'
    result = reg.exec(userAgent)
    if (result) { browser.version = result[1] }
  } else if (userAgent.includes('firefox')) {
    reg = /firefox\/(\S+)/g
    browser.name = 'Firefox'
    result = reg.exec(userAgent)
    if (result) { browser.version = result[1] }
  } else if (userAgent.includes('trident')) {
    browser.name = 'IE'
    if (userAgent.includes('msie')) {
      reg = /msie (\S+)/g
      result = reg.exec(userAgent)
      if (result) {
        browser.version = result[1]
        browser.version = browser.version.replace(';', '')
      }
    } else {
      reg = /rv:(\S+)/g
      result = reg.exec(userAgent)
      if (result) { browser.version = result[1] }
    }
  }
  return browser
}

export function toHHMMSS(value: string | number) {
  if (value) {
    let sec_num = parseInt(`${value}`, 10); // don't forget the second param
    let hours: string | number = Math.floor(sec_num / 3600);
    let minutes: string | number = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds: string | number = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }
  return "00:00:00"
}

export function timeInterval(inputdate: string | number) {
  let input = `${inputdate}`
  if (input.length === 10) { input += '000' }

  dayjs.locale('ko')
  dayjs.extend(relativeTime)
  if (typeof inputdate === 'string') {
    if (inputdate.includes('T')) {
      return dayjs(inputdate).fromNow()
    }
  }
  return dayjs(Number(input)).fromNow()
}

export function nl2br(originalString: string | undefined) {
  if (originalString === undefined) return ''
  if (!originalString) { return originalString }
  if (originalString) { return originalString.replace(/\n/g, '<br>') }
}

/**
 * 기본설정된 값 가져오기 위함
 * 타임스탬프 방식으로 처리하려고 만든 것
 * @param inputdate
 */
export function defaultDayjs(inputdate?: string | number) {
  let input = '' + inputdate
  if (input.length === 10) { input += '000' }

  dayjs.locale('ko')
  dayjs.extend(weekday)

  return inputdate ? dayjs(Number(input)) : dayjs() // 인풋이 아닌 인풋데이트의 여부로 따져야 하는 이유는 ''도 있다고 보는듯..
}

/**
 * 주어진 값이 `null` 또는 `undefined`가 아닌지 검사한다. `Array.filter` 메서드에서 유용하게 쓰인다.
 *
 * @example
 * const array: (string | null)[] = ['foo', 'bar', null, 'zoo', null];
 * const filteredArray: string[] = array.filter(notEmpty); // 리턴되는 타입이 `(string | null)[]` 가 아님에 주목!
 * @param value
 */
export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}


export function getCFImage(imageId: string, options: CloudFlareFlexibleVariants | 'thumbnail' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | DisplayBreakpoint, accountHash: string = 'iF917V18P88uIhA8kbNa1A') {
  const q = (() => {
    if (typeof options === 'string') {
      return options
    } else {
      return Object.entries(options).map(([key, value]) => `${key}=${value}`).join(',')
    }
  })()
  return `https://imagedelivery.net/${accountHash}/${imageId}/${q}`
}

/**
 * 특정영역만 잡아서 프린트하기 위한 함수. 지정한 id이하의 내용만 출력하며 css호환을 위해 아래 내용은 기본적으로 가져가는게 좋음
 <div :id="`printContents${item.no}`">
  <div class="v-application">
    <div class="vuetify text-center">
      들어가는 내용들
    </div>
  </div>
 </div>
 * @param targetId
 */
export function printSpecificArea(targetId: string) {
  const html = document.querySelector('html')
  const printContents = '' + document.querySelector('#' + targetId)?.innerHTML
  const printDiv = document.createElement('div')
  printDiv.className = 'print-div'

  html?.appendChild(printDiv)
  printDiv.innerHTML = printContents
  document.body.style.display = 'none'

  window.onbeforeprint = function () {
    document.body.style.display = 'none'
  }
  window.onafterprint = function () {
    document.body.style.display = 'block'
    printDiv.style.display = 'none'
  }

  try {
    document.execCommand('print', false, undefined)
  }
  catch (e) {
    window.print()
  }
  document.body.style.display = 'block'
  printDiv.style.display = 'none'
}
