import { defineStore, storeToRefs } from 'pinia'
import appConfig from '~~/app.config.value'
import { useDYUserStore } from '~~/modules/nuxt3-module-doyac/stores/user'

const { appId } = appConfig

export const useUserStore = defineStore(`${appId}-user`, () => {
  const parent = useDYUserStore()
  const refs = storeToRefs(parent)
  return {
    ...refs,
    init: parent.init,
    ready: parent.ready,
    injectUser: parent.injectUser,
    requestLogout: parent.requestLogout
  }
})