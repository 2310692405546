import { defineStore } from "pinia"
import { useDYFetch } from "../composables/fetch"

export interface ShortCutMenu {
  "title": string
  "icon": string
  "actions": [
    {
      "methodName": "METHOD_GO_TO_URL" | "METHOD_GO_TO_ROUTER",
      "methodParams": [
        string
      ]
      "methodStyle": string
      "platforms": number
    }
  ],
  "showAsAction": string
  "image": string | null
  "text": string
  "shortcutNavigation": {
    description: string,
    image: string,
    list: [any]
    // specialData: [any],
    specialData: SpecialShortCut
    specialType: string
  }
}

export type SpecialShortCut = {data:Record<string,specialData[]>, img?:string}[]
export type SpecialShortCutTour = {data:Record<string,specialData[]>, img?:string}

export interface specialData {
  board: string
  lect_mode: string
  lecture: string
  lectureName: string
  new_icon: string
  no: number
  qna_open: string
  showTitle: string
  teacher: string
}

export const useDYMainStore = defineStore('nuxt3-module-doyac-main', () => {
  const isLanding = ref(true)
  const isLoading = ref(false)
  const isRouteChanging = ref(false)
  const shortCutJson = ref('')
  const bannerJson = ref('')
  const actionbarTitle = ref('')
  const lastScrollY = ref(0)
  const bodyData = ref()

  const isSSR = computed(() => process.server)

  const getShortCut = async () => {
    const response = await useDYFetch<ShortCutMenu[]>(shortCutJson.value)
    return response.data.value
  }

  return {
    /**
     * 랜딩 상태 식별값. 이 값이 `true`라면 앱이 서버에서 기본 데이터를 불러들이는 작업 등을 진행 중이라는 뜻이다.
     */
    isLanding,
    /**
     * 전역 로딩 상태 식별값. 이 값이 `true`면 오버레이 형태의 로딩 UI가 노출될 수 있음을 뜻한다.
     */
    isLoading,
    /**
     * 라우트가 변하고 있는지 식별하는 값. `DYSwiper` 등의 컴퍼넌트가 사용함.
     */
    isRouteChanging,
    bannerJson,
    /**
     * 초기 외부에서 할당함
     */
    shortCutJson,
    /**
     * 상단 액션바 타이틀. 일정 width이하인 경우에만 노출
     */
    actionbarTitle,
    lastScrollY,
    bodyData,
    isSSR,
    getShortCut
  }
})